var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider, Typography } from "@mui/material";
import { LightTheme } from "./Theme";
import { TransitionPopupStackProvider } from "./components/TransitionPopup/TransitionPopup";
import { GlobalStateProvider } from "./providers/GlobalStateProvider";
import { FilterProvider } from "./providers/FilterProvider";
import { SpinnerProvider } from "./providers/SpinnerProvider";
import { FilterRestrictionProvider } from "./providers/FilterRestrictionProvider";
export var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60 * (60 * 1000),
            cacheTime: 90 * (60 * 1000),
        },
    },
});
var Application = function (_a) {
    var children = _a.children;
    return (_jsx(FilterRestrictionProvider, { children: _jsx(GlobalStateProvider, { children: _jsx(FilterProvider, { children: _jsx(SpinnerProvider, { children: _jsx(QueryClientProvider, __assign({ client: queryClient }, { children: _jsx(ThemeProvider, __assign({ theme: LightTheme }, { children: _jsx(TransitionPopupStackProvider, { children: _jsx(Typography, __assign({ component: "div", color: "textPrimary" }, { children: children })) }) })) })) }) }) }) }));
};
export default Application;
