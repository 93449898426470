var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext } from "react";
import { Box, styled, useTheme } from "@mui/material";
import { ColumnDescribedTable, ValidSortDirections, } from "../../components/Table/Table";
import FilteredOverviewPage from "../../components/FilteredOverviewPage";
import BackToButton from "../../components/BackToButton";
import { useGetLeaderScoresQuery, useGetScoreStateQuery, useGetSkillGroupLookupsQuery, } from "../../store/api/core";
import SpinnerAndErrorer from "../../components/SpinnerAndErrorer/SpinnerAndErrorer";
import { ScoreTypes, starsScoreFormatter, percentageScoreFormatter, } from "../../components/LevelFunctionMatrix/Variants";
import { emptyFilters, FilterContext, setFilters, getFilters, } from "../../providers/FilterProvider";
import { Status } from "../../common/action";
import { ScoreSelectorRow, textLowScorerGetter, } from "../../components/FilteredOverviewPage/FilteredOverviewPage";
import { NameColoredCircle } from "../../components/Table/NameColoredCircle";
import { getRoute } from "../../routes/getAllRoutes";
import { DefaultFilterSet } from "../../components/Filters/Filters";
import { useClient } from "../../hooks/client";
export var DefaultColoredTotalsCell = function (props) {
    var theme = useTheme();
    var text = props.text || "";
    var color = props.color;
    return (_jsx(Box, __assign({ sx: {
            color: { color: color },
            marginLeft: text == "Average" ? "56px" : "",
            fontFamily: text == "Average"
                ? theme.typography.fontFamily + " Heavy"
                : theme.typography.fontFamily,
        } }, { children: text })));
};
var ScoreBasedColoredTotalsCell = function (props) {
    var _a;
    var theme = useTheme();
    var text = props.text;
    var stateResponse = useGetScoreStateQuery({
        score: props.score.toString(),
        scoreType: props.scoreType,
    });
    var action = (_a = stateResponse === null || stateResponse === void 0 ? void 0 : stateResponse.data) === null || _a === void 0 ? void 0 : _a.state;
    var color = !action
        ? "nodata"
        : action === Status.Maintain
            ? theme.palette.success.main // Green
            : action === Status.Watch
                ? theme.palette.warning.main
                : theme.palette.error.main;
    var StyledSmallBox = styled(Box)(function () { return ({
        display: "flex",
        width: "60px",
        padding: "2px",
        alignItems: "flex-start",
        gap: "8px",
        borderRadius: "10px",
        border: "1px solid rgba(0, 33, 54, 0.20)",
        backgroundColor: color,
        textAlign: "center",
    }); });
    // Handle no average data to display
    if (text === undefined) {
        return _jsx(Box, { children: "--" });
    }
    return (_jsx(StyledSmallBox, { children: _jsx(Box, __assign({ sx: {
                fontFamily: theme.typography.fontFamily + " Heavy",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeigh: "400",
                lineHeight: "20px",
                margin: "auto",
            } }, { children: text })) }));
};
export function AssessmentTable(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var useScores = _a.useScores, sortOns = _a.sortOns, defaultSortOn = _a.defaultSortOn, filterFunctor = _a.filterFunctor, mutateColumnDescriptions = _a.mutateColumnDescriptions;
    var theme = useTheme();
    var getFilters = useContext(FilterContext).getFilters;
    var scoreType = (_b = getFilters()) === null || _b === void 0 ? void 0 : _b.scoreType;
    var leaderScores = useScores({
        filters: getFilters(),
    });
    var data = (_d = (_c = leaderScores === null || leaderScores === void 0 ? void 0 : leaderScores.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.leaders;
    var client = useClient();
    var skillGroupLabels = useGetSkillGroupLookupsQuery({
        clientId: client.clientId,
    });
    var ScoreFormatter = function (_a) {
        var data = _a.data;
        if (data.score === undefined) {
            return _jsx(Box, { children: "--" });
        }
        return _jsx(Box, { children: data.score });
    };
    var tableData = Array.isArray(data) &&
        data.filter(function (record) { return filterFunctor(record, scoreType); });
    var scoreKeys = [
        "time_in_role",
        "performance_rating",
        "succession_plans_count",
        "overall_score",
        "ai_score",
        "heidrick_score",
        "organizational",
        "functional",
        "capability",
        "potential",
    ];
    var scoreSums = scoreKeys.map(function () { return []; });
    var scores;
    if (data) {
        scores = data.filter(function (record) { return filterFunctor(record, scoreType); });
        scores.forEach(function (leader) {
            scoreKeys.map(function (scoreKey, keyIndex) {
                // Some leaders may be missing assessments (denoted by null value). Those
                // should not be included in the average
                if (leader[scoreKey] !== null) {
                    scoreSums[keyIndex].push(leader[scoreKey]);
                }
            });
        });
    }
    var totalRows = scoreSums.map(function (sums) {
        return (sums.reduce(function (runningTotal, currentValue) { return runningTotal + currentValue; }, 0) / sums.length);
    });
    var defaultSortKeyOrder = sortOns
        ? sortOns(scoreType || defaultSortOn)
        : undefined;
    var columnDescriptions = [
        {
            header: "Name",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return _jsx(NameColoredCircle, { data: data });
            },
            totalCell: (_jsx(DefaultColoredTotalsCell, { text: "Average", color: theme.palette.primary.dark })),
            valueTransformer: function (row) { return ({
                sort: "".concat(row.first_name.toLowerCase(), " ").concat(row.last_name.toLowerCase()),
                data: {
                    firstName: row.first_name,
                    lastName: row.last_name,
                    roleName: row.role_name,
                    efId: row.ef_id,
                },
            }); },
        },
        {
            header: "Time in Role",
            formatter: ScoreFormatter,
            defaultSortOrderKey: "timeInRole",
            defaultSortDirection: ValidSortDirections.ASC,
            totalCell: (_jsx(DefaultColoredTotalsCell, { text: isNaN(totalRows[0]) ? "--" : "".concat((_e = totalRows[0]) === null || _e === void 0 ? void 0 : _e.toFixed(1), " years"), score: totalRows[0] })),
            valueTransformer: function (row) {
                var _a;
                return ({
                    sort: row.time_in_role,
                    data: {
                        score: "".concat((_a = row.time_in_role) === null || _a === void 0 ? void 0 : _a.toFixed(1), " years"),
                    },
                });
            },
        },
        {
            header: "Performance Rating",
            formatter: ScoreFormatter,
            defaultSortDirection: ValidSortDirections.ASC,
            totalCell: _jsx(DefaultColoredTotalsCell, { text: "N/A" }),
            valueTransformer: function (row) { return ({
                sort: row.performance_rating,
                data: {
                    score: row.performance_rating,
                },
            }); },
        },
        {
            header: "Succession Plans",
            formatter: ScoreFormatter,
            defaultSortOrderKey: "succession",
            defaultSortDirection: ValidSortDirections.ASC,
            totalCell: (_jsx(DefaultColoredTotalsCell, { text: isNaN(totalRows[2]) ? "--" : "".concat((_f = totalRows[2]) === null || _f === void 0 ? void 0 : _f.toFixed(1)), score: totalRows[2] ? totalRows[2] : 0 })),
            valueTransformer: function (row) { return ({
                sort: row.succession_plans_count,
                data: {
                    score: row.succession_plans_count,
                },
            }); },
        },
        {
            header: "Match Score",
            formatter: ScoreFormatter,
            defaultSortDirection: ValidSortDirections.ASC,
            defaultSortOrderKey: ScoreTypes.Overall,
            isHighlighted: scoreType == ScoreTypes.Overall,
            totalCell: (_jsx(ScoreBasedColoredTotalsCell, { text: starsScoreFormatter({ value: totalRows[3] }), score: totalRows[3], scoreType: "Match" })),
            valueTransformer: function (row) { return ({
                sort: row.overall_score,
                data: {
                    score: starsScoreFormatter({ value: row.overall_score }),
                },
            }); },
        },
        {
            header: "Heidrick Score",
            formatter: ScoreFormatter,
            defaultSortOrderKey: ScoreTypes.Heidrick,
            defaultSortDirection: ValidSortDirections.ASC,
            isHighlighted: scoreType == ScoreTypes.Heidrick,
            totalCell: (_jsx(ScoreBasedColoredTotalsCell, { text: starsScoreFormatter({ value: totalRows[5] }), score: totalRows[5], scoreType: "Heidrick" })),
            valueTransformer: function (row) { return ({
                sort: row.heidrick_score,
                data: {
                    score: starsScoreFormatter({ value: row.heidrick_score }),
                },
            }); },
        },
        {
            header: "AI Score",
            formatter: ScoreFormatter,
            defaultSortOrderKey: ScoreTypes.AI,
            defaultSortDirection: ValidSortDirections.ASC,
            isHighlighted: scoreType == ScoreTypes.AI,
            totalCell: (_jsx(ScoreBasedColoredTotalsCell, { text: starsScoreFormatter({ value: totalRows[4] }), score: totalRows[4], scoreType: "AI" })),
            valueTransformer: function (row) { return ({
                sort: row.ai_score,
                data: {
                    score: starsScoreFormatter({ value: row.ai_score }),
                },
            }); },
        },
        {
            header: (_g = skillGroupLabels.data) === null || _g === void 0 ? void 0 : _g.funcImpact,
            formatter: ScoreFormatter,
            defaultSortOrderKey: ScoreTypes.Functional,
            defaultSortDirection: ValidSortDirections.ASC,
            isHighlighted: scoreType == ScoreTypes.Functional,
            totalCell: (_jsx(ScoreBasedColoredTotalsCell, { text: percentageScoreFormatter({ value: totalRows[7] }), score: totalRows[7], scoreType: "Functional" })),
            valueTransformer: function (row) { return ({
                sort: row.functional,
                data: {
                    score: percentageScoreFormatter({ value: row.functional }),
                },
            }); },
        },
        {
            header: (_h = skillGroupLabels.data) === null || _h === void 0 ? void 0 : _h.orgImpact,
            formatter: ScoreFormatter,
            defaultSortOrderKey: ScoreTypes.Organizational,
            defaultSortDirection: ValidSortDirections.ASC,
            isHighlighted: scoreType == ScoreTypes.Organizational,
            totalCell: (_jsx(ScoreBasedColoredTotalsCell, { text: percentageScoreFormatter({ value: totalRows[6] }), score: totalRows[6], scoreType: "Organizational" })),
            valueTransformer: function (row) { return ({
                sort: row.organizational,
                data: {
                    score: percentageScoreFormatter({ value: row.organizational }),
                },
            }); },
        },
        {
            header: (_j = skillGroupLabels.data) === null || _j === void 0 ? void 0 : _j.capability,
            formatter: ScoreFormatter,
            defaultSortOrderKey: ScoreTypes.Capability,
            defaultSortDirection: ValidSortDirections.ASC,
            isHighlighted: scoreType == ScoreTypes.Capability,
            totalCell: (_jsx(ScoreBasedColoredTotalsCell, { text: percentageScoreFormatter({ value: totalRows[8] }), score: totalRows[8], scoreType: "Capabilities" })),
            valueTransformer: function (row) { return ({
                sort: row.capability,
                data: {
                    score: percentageScoreFormatter({ value: row.capability }),
                },
            }); },
        },
        {
            header: (_k = skillGroupLabels.data) === null || _k === void 0 ? void 0 : _k.potential,
            formatter: ScoreFormatter,
            defaultSortOrderKey: ScoreTypes.Potential,
            defaultSortDirection: ValidSortDirections.ASC,
            isHighlighted: scoreType == ScoreTypes.Potential,
            totalCell: (_jsx(ScoreBasedColoredTotalsCell, { text: percentageScoreFormatter({ value: totalRows[9] }), score: totalRows[9], scoreType: "Potential" })),
            valueTransformer: function (row) { return ({
                sort: row.potential,
                data: {
                    score: percentageScoreFormatter({ value: row.potential }),
                },
            }); },
        },
    ];
    var mutatedColumnDescriptions = mutateColumnDescriptions
        ? mutateColumnDescriptions(columnDescriptions, scores)
        : columnDescriptions;
    return (_jsx(SpinnerAndErrorer, __assign({ conditions: [leaderScores] }, { children: _jsx(ColumnDescribedTable, { data: tableData, showingInfo: function (_a) {
                var total = _a.total;
                return "Showing ".concat(total, " leader").concat(total != 1 ? "s" : "");
            }, defaultSortKeyOrder: defaultSortKeyOrder, columnDescriptions: mutatedColumnDescriptions, replaceEmpty: "--", title: "" }) })));
}
var LocalTable = function (_a) {
    var filterFunctor = _a.filterFunctor;
    var sortOns = function (key) {
        var _a;
        return ((_a = {},
            _a[ScoreTypes.Overall] = [
                {
                    column: ScoreTypes.Overall,
                    direction: ValidSortDirections.ASC,
                },
            ],
            _a[ScoreTypes.Heidrick] = [
                { column: ScoreTypes.Heidrick, direction: ValidSortDirections.ASC },
            ],
            _a[ScoreTypes.AI] = [
                { column: ScoreTypes.AI, direction: ValidSortDirections.ASC },
            ],
            _a[ScoreTypes.Functional] = [
                { column: ScoreTypes.Functional, direction: ValidSortDirections.ASC },
            ],
            _a[ScoreTypes.Organizational] = [
                {
                    column: ScoreTypes.Organizational,
                    direction: ValidSortDirections.ASC,
                },
            ],
            _a[ScoreTypes.Capability] = [
                { column: ScoreTypes.Capability, direction: ValidSortDirections.ASC },
            ],
            _a[ScoreTypes.Potential] = [
                { column: ScoreTypes.Potential, direction: ValidSortDirections.ASC },
            ],
            _a)[key]);
    };
    return (_jsx(AssessmentTable, { useScores: useGetLeaderScoresQuery, filterFunctor: filterFunctor || (function () { return true; }), defaultSortOn: ScoreTypes.Overall, sortOns: sortOns }));
};
export var AssessmentPage = function (_a) {
    var backTo = _a.backTo, filterFunctor = _a.filterFunctor, filterClearer = _a.filterClearer, scoreCards = _a.scoreCards;
    var defaultFilterClearer = function () { var _a; return setFilters(__assign(__assign({}, emptyFilters), { scoreType: (_a = getFilters()) === null || _a === void 0 ? void 0 : _a.scoreType })); };
    var title = "Intelligence - Leaders";
    var availableFilters = new Set(__spreadArray([], Array.from(DefaultFilterSet), true));
    return (_jsx(FilteredOverviewPage, __assign({ availableFilters: availableFilters, title: title, secondaryHeadingTitle: "Leader Assessment Results", backToButton: backTo || _jsx(BackToButton, { title: "Summary", url: getRoute("Dashboard") }), scoreCards: scoreCards, filterClearer: filterClearer || defaultFilterClearer }, { children: _jsx(LocalTable, { filterFunctor: filterFunctor }) })));
};
export var RiskAssessmentPage = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var client = useClient();
    var skillGroups = useGetSkillGroupLookupsQuery({
        clientId: client.clientId,
    });
    var scoreInfos = [
        {
            scoreType: ScoreTypes.Overall,
            tooltip: "Percentage of leaders with Match Scores indicating significant gaps against role requirements and/or AI-derived success factors.",
            title: "Leaders with low Match Scores",
        },
        {
            scoreType: ScoreTypes.Heidrick,
            tooltip: "Percentage of leaders with Heidrick Scores indicating significant gaps against functional impact, organizational impact, capability, and/or potential requirements.",
            title: "Leaders with low Heidrick Scores",
        },
        {
            scoreType: ScoreTypes.AI,
            tooltip: "Percentage of leaders with AI score indicating significant gaps in current roles from work history, skills, and qualifications.",
            title: "Leaders with low AI Scores",
        },
        {
            scoreType: ScoreTypes.Functional,
            tooltip: "Percentage of leaders with ".concat((_a = skillGroups.data) === null || _a === void 0 ? void 0 : _a.funcImpact, " Scores indicating significant gaps against ").concat((_b = skillGroups.data) === null || _b === void 0 ? void 0 : _b.funcImpact, " requirements."),
            title: "Leaders with low ".concat((_c = skillGroups.data) === null || _c === void 0 ? void 0 : _c.funcImpact, " Scores"),
        },
        {
            scoreType: ScoreTypes.Organizational,
            tooltip: "Percentage of leaders with ".concat((_d = skillGroups.data) === null || _d === void 0 ? void 0 : _d.orgImpact, " Scores indicating significant gaps against ").concat((_e = skillGroups.data) === null || _e === void 0 ? void 0 : _e.orgImpact, " requirements."),
            title: "Leaders with low ".concat((_f = skillGroups.data) === null || _f === void 0 ? void 0 : _f.orgImpact, " Scores"),
        },
        {
            scoreType: ScoreTypes.Capability,
            tooltip: "Percentage of leaders with ".concat((_g = skillGroups.data) === null || _g === void 0 ? void 0 : _g.capability, " Scores indicating significant gaps against ").concat((_h = skillGroups.data) === null || _h === void 0 ? void 0 : _h.capability, " requirements."),
            title: "Leaders with low ".concat((_j = skillGroups.data) === null || _j === void 0 ? void 0 : _j.capability, " Scores"),
        },
        {
            scoreType: ScoreTypes.Potential,
            tooltip: "Percentage of leaders with ".concat((_k = skillGroups.data) === null || _k === void 0 ? void 0 : _k.potential, " Scores indicating significant gaps against ").concat((_l = skillGroups.data) === null || _l === void 0 ? void 0 : _l.potential, " requirements."),
            title: "Leaders with low ".concat((_m = skillGroups.data) === null || _m === void 0 ? void 0 : _m.potential, " Scores"),
        },
    ];
    return (_jsx(AssessmentPage, { backTo: _jsx(BackToButton, { title: "Risk", url: getRoute("DashboardLeadershipRisk") }), scoreCards: _jsx(ScoreSelectorRow, { textGetter: textLowScorerGetter, initialScoreType: ScoreTypes.Overall, scoreInfos: scoreInfos, useScores: useGetLeaderScoresQuery }), filterFunctor: function (record, scoreType) {
            return scoreType == null || (record === null || record === void 0 ? void 0 : record.low_scores.indexOf(scoreType)) > -1;
        }, filterClearer: function () { return setFilters(emptyFilters); } }));
};
export default AssessmentPage;
