var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import LeaderCount from "../../components/LeaderCount/";
import ScoreCard from "../ScoreCard/ScoreCard";
import { FilterContext, emptyFilters } from "../../providers/FilterProvider";
import { AvailableFilters, Filters, LandingPageFilters, } from "../Filters/Filters";
import styles from "./HeatmappedPage.module.scss";
import { Stack } from "@eightfold.ai/octuple";
import { useClient } from "../../hooks/client";
import { useGetSkillGroupLookupsQuery } from "../../store/api/core";
var LocalLeaderCount = function (_a) {
    var _b, _c, _d;
    var useOverview = _a.useOverview, useScores = _a.useScores, countText = _a.countText;
    var getFilters = useContext(FilterContext).getFilters;
    var overview = useOverview();
    var scores = useScores({
        filters: __assign(__assign({}, getFilters()), { scoreType: getFilters().dashboardScoreType }),
    });
    var totalNumberCount = (scores === null || scores === void 0 ? void 0 : scores.isSuccess)
        ? (_b = scores === null || scores === void 0 ? void 0 : scores.data) === null || _b === void 0 ? void 0 : _b.getLeaderCount()
        : (_d = (_c = overview === null || overview === void 0 ? void 0 : overview.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.leaders;
    return _jsx(LeaderCount, { leadersCount: totalNumberCount, countText: countText });
};
var ScoreKeys;
(function (ScoreKeys) {
    ScoreKeys["Functional"] = "functional";
    ScoreKeys["Organizational"] = "organizational";
    ScoreKeys["Potential"] = "potential";
    ScoreKeys["Capability"] = "capability";
    ScoreKeys["Heidrick"] = "heidrick_score";
})(ScoreKeys || (ScoreKeys = {}));
var HeatmapSet = function (_a) {
    var _b, _c;
    var useScores = _a.useScores, heatmaps = _a.heatmaps;
    var _d = useContext(FilterContext), getFilters = _d.getFilters, setFilters = _d.setFilters, updateFilters = _d.updateFilters;
    var dashboardScoreType = getFilters().dashboardScoreType;
    var foundHeatmapIndex = heatmaps.findIndex(function (_a) {
        var scoreKey = _a.scoreKey;
        return dashboardScoreType && dashboardScoreType == scoreKey;
    });
    var _e = useState(foundHeatmapIndex < 0 ? 0 : foundHeatmapIndex), heatmap = _e[0], setHeatmap = _e[1];
    var scores = useScores({
        filters: __assign(__assign({}, getFilters()), { scoreType: getFilters().dashboardScoreType }),
    });
    var _f = getFilters(), location = _f.location, business_unit = _f.business_unit, ninebox_id = _f.ninebox_id, assigned_box = _f.assigned_box, location_2 = _f.location_2, division = _f.division, roleProfileId = _f.roleProfileId, retentionRisk = _f.retentionRisk, careerInterests = _f.careerInterests, assessmentSource = _f.assessmentSource, ageRange = _f.ageRange, derailerRisk = _f.derailerRisk, willingToRelocate = _f.willingToRelocate, gender = _f.gender, ethnicity = _f.ethnicity, nineboxRating = _f.nineboxRating, hightPotentialLeader = _f.hightPotentialLeader;
    useEffect(function () {
        var scoreType = dashboardScoreType;
        setFilters(__assign(__assign({}, emptyFilters), { scoreType: scoreType, dashboardScoreType: scoreType, location: location || [], business_unit: business_unit || [], ninebox_id: ninebox_id || undefined, hightPotentialLeader: hightPotentialLeader || undefined, assigned_box: assigned_box || [], location_2: location_2 || [], division: division || [], roleProfileId: roleProfileId || [], retentionRisk: retentionRisk || [], careerInterests: careerInterests || [], assessmentSource: assessmentSource || [], ageRange: ageRange || [], derailerRisk: derailerRisk || [], willingToRelocate: willingToRelocate || [], gender: gender || [], ethnicity: ethnicity || [], nineboxRating: nineboxRating || [] }));
    }, []);
    var heatmapOptions = heatmaps === null || heatmaps === void 0 ? void 0 : heatmaps.map(function (_a, index) {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        var label = _a.label, scoreKey = _a.scoreKey, scoreFormatter = _a.scoreFormatter, description = _a.description;
        var cell = (_e = (_d = (_c = (_b = scores === null || scores === void 0 ? void 0 : scores.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.functions[0]) === null || _d === void 0 ? void 0 : _d.sub_functions) === null || _e === void 0 ? void 0 : _e[0];
        var formattedScore = scoreFormatter(cell === null || cell === void 0 ? void 0 : cell[scoreKey], cell === null || cell === void 0 ? void 0 : cell.leaders);
        var action = (_f = cell === null || cell === void 0 ? void 0 : cell[scoreKey]) === null || _f === void 0 ? void 0 : _f.state;
        var selected = heatmap == index;
        var client = useClient();
        var skillGroupLookups = useGetSkillGroupLookupsQuery({
            clientId: client.clientId,
        });
        if (label != undefined) {
            if (scoreKey == ScoreKeys.Heidrick) {
                description = description
                    .replace("functional impact", (_g = skillGroupLookups.data) === null || _g === void 0 ? void 0 : _g.funcImpact.toLowerCase())
                    .replace("organizational impact", (_h = skillGroupLookups.data) === null || _h === void 0 ? void 0 : _h.orgImpact.toLowerCase())
                    .replace("capability", (_j = skillGroupLookups.data) === null || _j === void 0 ? void 0 : _j.capability.toLowerCase())
                    .replace("potential", (_k = skillGroupLookups.data) === null || _k === void 0 ? void 0 : _k.potential.toLowerCase());
            }
            if (scoreKey == ScoreKeys.Functional) {
                label = label.replace("Functional Impact", (_l = skillGroupLookups.data) === null || _l === void 0 ? void 0 : _l.funcImpact);
                description = description.replaceAll("Functional Impact", (_m = skillGroupLookups.data) === null || _m === void 0 ? void 0 : _m.funcImpact);
            }
            if (scoreKey == ScoreKeys.Organizational) {
                label = label.replace("Organizational Impact", (_o = skillGroupLookups.data) === null || _o === void 0 ? void 0 : _o.orgImpact);
                description = description.replaceAll("Organizational Impact", (_p = skillGroupLookups.data) === null || _p === void 0 ? void 0 : _p.orgImpact);
            }
            if (scoreKey == ScoreKeys.Potential) {
                label = label.replace("Potential", (_q = skillGroupLookups.data) === null || _q === void 0 ? void 0 : _q.potential);
                description = description.replaceAll("Potential", (_r = skillGroupLookups.data) === null || _r === void 0 ? void 0 : _r.potential);
            }
            if (scoreKey == ScoreKeys.Capability) {
                label = label.replace("Capability", (_s = skillGroupLookups.data) === null || _s === void 0 ? void 0 : _s.capability);
                description = description.replaceAll("Capability", (_t = skillGroupLookups.data) === null || _t === void 0 ? void 0 : _t.capability);
            }
        }
        return (_jsx(ScoreCard, { title: label, score: formattedScore, action: action, selected: selected, onClick: function () {
                updateFilters({ dashboardScoreType: scoreKey });
                setHeatmap(index);
            }, tooltip: description }, index));
    });
    var LegendRow = styled(Box)({
        marginTop: "32px",
    });
    return (_jsxs(_Fragment, { children: [_jsx(LegendRow, { children: (_b = heatmaps[heatmap]) === null || _b === void 0 ? void 0 : _b.legend }), _jsx("div", __assign({ className: styles.scoreCardStack }, { children: _jsx(Stack, __assign({ gap: "s" }, { children: heatmapOptions })) })), _jsx(Box, __assign({ sx: { margin: "0 32px" } }, { children: (_c = heatmaps[heatmap]) === null || _c === void 0 ? void 0 : _c.implementation }))] }));
};
function HeatmappedPage(_a) {
    var inside = _a.heading.inside, useOverview = _a.useOverview, useScores = _a.useScores, heatmaps = _a.heatmaps, countText = _a.countText, rightTrailer = _a.rightTrailer;
    var InnerFilterLegendContainer = styled(Box)({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 32px",
    });
    LandingPageFilters.delete(AvailableFilters.Function);
    LandingPageFilters.delete(AvailableFilters.SubFunction);
    LandingPageFilters.delete(AvailableFilters.Level);
    return (_jsxs(Box, __assign({ "data-testid": "HeatmappedPage" }, { children: [_jsx(Box, __assign({ sx: {
                    padding: "32px 32px 0",
                } }, { children: inside })), _jsx(InnerFilterLegendContainer, { children: _jsx(Filters, { availableFilters: LandingPageFilters, leftTrailer: _jsx(LocalLeaderCount, { useScores: useScores, useOverview: useOverview, countText: countText }), rightTrailer: rightTrailer }) }), _jsx(HeatmapSet, { useOverview: useOverview, useScores: useScores, heatmaps: heatmaps })] })));
}
export default HeatmappedPage;
