import { useGetClientDetailsQuery } from "../store/api/core";
import { hasPermissionExist } from "../store/api/permissions";
export var usePermissions = function () {
    var _a, _b, _c;
    var hasDiversityPermissionExist = hasPermissionExist("ORG_DASHBOARD_UI/DIVERSITY/SETTINGS");
    var hasDiversityPermission = hasPermissionExist("ORG_DASHBOARD_UI/DIVERSITY");
    var hasTalentReviewPermissionExist = hasPermissionExist("ORG_DASHBOARD_UI/TALENT_REVIEW");
    var hasSuccessionPermissionExist = hasPermissionExist("ORG_DASHBOARD_UI/SUCCESSION");
    var hasMobilityPermissionExist = hasPermissionExist("ORG_DASHBOARD_UI/MOBILITY");
    var clientDetails = useGetClientDetailsQuery({});
    var is_nine_box_enabled = ((_a = clientDetails.data) === null || _a === void 0 ? void 0 : _a.data.nine_box_enabled) || false;
    var showDiversitySpecific = ((_c = (_b = clientDetails === null || clientDetails === void 0 ? void 0 : clientDetails.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.hide_gender_and_ethnicity) == false;
    return {
        nineBox: hasTalentReviewPermissionExist && is_nine_box_enabled,
        diversitySetting: hasDiversityPermissionExist,
        diversity: hasDiversityPermission,
        succession: hasSuccessionPermissionExist,
        mobility: hasMobilityPermissionExist,
        showDiversitySpecific: showDiversitySpecific,
        conditions: [clientDetails],
    };
};
