import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { IconName } from "@eightfold.ai/octuple";
import { downloadCsvFile } from "../../store/api/core";
import { OutlinedButton } from "../OutlinedButton/OutlinedButton";
import { ButtonVariant, IconButton } from "../Button/Button";
export var IconDownloadButton = function (_a) {
    var filename = _a.filename, url = _a.url;
    var _b = useState(false), downloadingCsv = _b[0], setDownloadingCsv = _b[1];
    return (_jsx(IconButton, { iconPath: IconName.mdiDownload, loading: downloadingCsv, variant: ButtonVariant.Neutral, onClick: function () {
            setDownloadingCsv(true);
            downloadCsvFile(filename, url, function () {
                setDownloadingCsv(false);
            });
        } }));
};
var DownloadButton = function (_a) {
    var buttonText = _a.buttonText, filename = _a.filename, url = _a.url;
    var _b = useState(false), downloadingCsv = _b[0], setDownloadingCsv = _b[1];
    return (_jsx(OutlinedButton, { buttonText: buttonText, icon: IconName.mdiDownload, loading: downloadingCsv, onClick: function () {
            setDownloadingCsv(true);
            downloadCsvFile(filename, url, function () {
                setDownloadingCsv(false);
            });
        } }));
};
export default DownloadButton;
