var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
export var getRestrictionArrays = function (restrictions) {
    return Object.entries(restrictions || {}).reduce(function (current, _a) {
        var _b;
        var key = _a[0], value = _a[1];
        return (__assign(__assign({}, current), (_b = {}, _b[key] = Array.from(value), _b)));
    }, {});
};
export var FilterRestrictionContext = React.createContext({
    restrictions: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setRestrictions: function () { },
});
export var FilterRestrictionProvider = function (_a) {
    var children = _a.children;
    var _b = useState({}), restrictions = _b[0], setRestrictions = _b[1];
    return (_jsx(FilterRestrictionContext.Provider, __assign({ value: { restrictions: restrictions, setRestrictions: setRestrictions } }, { children: children })));
};
