var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Box, styled } from "@mui/material";
import RightArrow from "../RightArrow";
import { Progress } from "@eightfold.ai/octuple";
import { useNavigate } from "../../hooks/navigate";
import { getRoute } from "../../routes/getAllRoutes";
import styles from "./PipelineStatusCards.module.scss";
var PipelineStatusCards = function (_a) {
    var successLeadersPipelines = _a.successLeadersPipelines, pipelineUrl = _a.pipelineUrl;
    var title = successLeadersPipelines.title, desc = successLeadersPipelines.desc, totalLeaders = successLeadersPipelines.totalLeaders, inPipelineLeaders = successLeadersPipelines.inPipelineLeaders, notInPipelineLeaders = successLeadersPipelines.notInPipelineLeaders;
    var inPipelineCal = (inPipelineLeaders / totalLeaders) * 100;
    var notInPipelineCal = (notInPipelineLeaders / totalLeaders) * 100;
    var inPipelinePer = Number.isNaN(inPipelineCal) ? 0 : inPipelineCal;
    var notInPipelinePer = Number.isNaN(notInPipelineCal)
        ? 0
        : notInPipelineCal;
    var navigate = useNavigate();
    var Container = styled(Box)({
        padding: "24px",
        borderRadius: "25px",
        border: "1px solid #D9DCE1",
    });
    var StyleLabel = styled(Box)(function (_a) {
        var theme = _a.theme;
        return ({
            fontFamily: theme.typography.fontFamily + " Heavy",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "28px",
            color: "#1A212E",
        });
    });
    var StyleDesc = styled(Box)(function (_a) {
        var theme = _a.theme;
        return ({
            fontFamily: theme.typography.fontFamily + " Book",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#0E2E26",
        });
    });
    var ProgressWrapper = styled(Box)({
        padding: "32px 24px 48px",
        borderRadius: "16px",
        backgroundColor: "#F6F7F8",
        lineHeight: "20px",
        marginTop: "10px",
    });
    var DescriptionBox = styled(Box)({
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    });
    var ProgressLabel = styled(Box)(function (_a) {
        var theme = _a.theme;
        return ({
            fontSize: "14px",
            fontWeight: "400",
            fontFamily: theme.typography.fontFamily + " Book",
            color: "#0E2E26",
            marginRight: "4px",
            textWrap: "nowrap",
        });
    });
    var handleOnPipelineClick = function (key) {
        navigate(getRoute(key));
    };
    return (_jsx(Container, __assign({ "data-testid": "PipelineStatusCards" }, { children: _jsxs(Box, { children: [_jsxs(Box, { children: [_jsxs(StyleLabel, { children: [title, " leaders (", totalLeaders, ")"] }), _jsxs(StyleDesc, { children: [desc, " % of leaders in pipelines compared to all"] })] }), _jsxs(ProgressWrapper, { children: [_jsxs(Box, __assign({ sx: { marginBottom: "32px" } }, { children: [_jsxs(DescriptionBox, __assign({ onClick: function () { return handleOnPipelineClick(pipelineUrl === null || pipelineUrl === void 0 ? void 0 : pipelineUrl.inPipeline); } }, { children: [_jsxs(ProgressLabel, { children: [inPipelinePer.toFixed(0), "% are in a pipeline (", inPipelineLeaders, " leader", inPipelineLeaders != 1 ? "s" : "", ")"] }), _jsx(RightArrow, {})] })), _jsx("style", { children: "\n        .".concat(styles.customProgressWrapper, " .progress-inner_WocghUW {\n          background: transparent !important;\n          border: none !important;\n        }\n        .").concat(styles.customProgressWrapper, " .bordered_YwuTdxo {\n          border: 1px solid #8A9FAB !important;\n        }\n      ") }), _jsx("div", __assign({ className: styles.customProgressWrapper }, { children: _jsx(Progress, { showLabels: false, percent: inPipelinePer }) }))] })), _jsxs(Box, { children: [_jsxs(DescriptionBox, __assign({ onClick: function () { return handleOnPipelineClick(pipelineUrl === null || pipelineUrl === void 0 ? void 0 : pipelineUrl.notInPipeline); } }, { children: [_jsxs(ProgressLabel, { children: [notInPipelinePer.toFixed(0), "% are NOT in a pipeline (", notInPipelineLeaders, " leader", notInPipelineLeaders != 1 ? "s" : "", ")"] }), _jsx(RightArrow, {})] })), _jsx("div", __assign({ className: styles.customProgressWrapper }, { children: _jsx(Progress, { showLabels: false, strokeColor: {
                                            from: "#7BE4F4",
                                            to: "#7BE4F4",
                                        }, percent: notInPipelinePer }) }))] })] })] }) })));
};
export default PipelineStatusCards;
