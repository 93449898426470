var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { FilterContext } from "../../providers/FilterProvider";
import Dropdown from "../Dropdown/Dropdown";
import { useGetAvailableNineBoxes, useGetNineBoxCategoriesFilter, } from "../../store/api/core";
import { MultiSelectFilter } from "../Filters/MultiSelectFilter";
import { haveTalentReviewPages } from "../../common/featureFlags";
import { usePermissions } from "../../hooks/permissions";
import { Stack } from "@eightfold.ai/octuple";
export var NineBoxFilter = function () {
    var _a, _b;
    var nineBox = usePermissions().nineBox;
    var _c = useContext(FilterContext), updateFilters = _c.updateFilters, getFilters = _c.getFilters;
    var optionResponse = useGetAvailableNineBoxes({ completed: true });
    if (!(haveTalentReviewPages() && nineBox)) {
        return null;
    }
    var filterOptions = __spreadArray([], (Array.isArray((_a = optionResponse === null || optionResponse === void 0 ? void 0 : optionResponse.data) === null || _a === void 0 ? void 0 : _a.data)
        ? optionResponse.data.data
            .filter(function (value) { return value.boxName; })
            .map(function (value) { return ({
            label: value.boxName,
            id: value.id,
        }); })
            .sort(function (a, b) { return a.label.localeCompare(b.label); })
        : []), true);
    var nineBoxFilter = (_b = getFilters()) === null || _b === void 0 ? void 0 : _b.ninebox_id;
    var initial = nineBoxFilter || 0;
    var options = filterOptions.map(function (option) { return ({
        label: option.label,
        value: option.id,
        onChange: function () {
            if (option.label === "All") {
                updateFilters({ ninebox_id: undefined, assigned_box: [] });
            }
            else {
                updateFilters({ ninebox_id: option.id, assigned_box: [] });
            }
        },
    }); });
    return (_jsxs(Stack, __assign({ direction: "vertical", flexGap: "s" }, { children: [filterOptions.length > 0 ? (_jsx(Dropdown, { initial: [initial], options: options, label: "9 Box", inactiveLabel: "All" }, "Nine-Box-".concat(nineBoxFilter, "-").concat(nineBoxFilter))) : (_jsx(NineBoxDisabled, {})), nineBoxFilter ? (_jsx(NineBoxCategories, { selectedId: nineBoxFilter })) : (_jsx(NineBoxCategoriesDisabled, {}))] })));
};
var NineBoxDisabled = function () {
    return (_jsx("div", __assign({ style: { pointerEvents: "none", opacity: "0.5" } }, { children: _jsx(Dropdown, { options: [], label: "9 Box" }) })));
};
var NineBoxCategoriesDisabled = function () {
    return (_jsx("div", __assign({ style: { pointerEvents: "none", opacity: "0.5" } }, { children: _jsx(MultiSelectFilter, { entity: "assigned_box", label: "9 Box Category" }) })));
};
var useNineBoxCategoryFilters = function (_a) {
    var _b, _c;
    var selectedId = _a.selectedId;
    var filterOptionsResponse = useGetNineBoxCategoriesFilter({
        id: selectedId,
    });
    var dataArray = (_c = (_b = filterOptionsResponse === null || filterOptionsResponse === void 0 ? void 0 : filterOptionsResponse.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.boxes;
    var assigned_box = __spreadArray([], (dataArray || []).map(function (box) { return ({
        label: box.name,
        value: box.assignedId,
    }); }), true);
    return function () {
        return {
            data: { data: { assigned_box: assigned_box } },
        };
    };
};
var NineBoxCategories = function (_a) {
    var selectedId = _a.selectedId;
    return (_jsx("div", { children: _jsx(MultiSelectFilter, { useOptionsQuery: useNineBoxCategoryFilters({ selectedId: selectedId }), entity: "assigned_box", label: "9 Box Category" }) }));
};
