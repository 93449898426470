/* eslint-enable no-var */
var isJestRunning;
try {
    isJestRunning =
        process.env.JEST_WORKER_ID !== undefined && process.env.NODE_ENV == "test";
}
catch (e) {
    isJestRunning = false;
}
var hasStorybook = global && global.IS_STORYBOOK;
export var haveDiversityPages = function () {
    return (hasStorybook ||
        isJestRunning ||
        process.env.REACT_APP_HAVE_DIVERSITY_PAGES ||
        localStorage.getItem("haveDiversityPages"));
};
export var haveSuccessProfilePages = function () { return true; };
export var haveTalentReviewPages = function () {
    return (hasStorybook ||
        isJestRunning ||
        process.env.REACT_APP_HAVE_TALENT_REVIEW_PAGES ||
        localStorage.getItem("haveTalentReviewPages"));
};
export var haveLegacyTabs = function () {
    return (process.env.REACT_APP_HEADER_ENABLED_FLAG ||
        localStorage.getItem("haveLegacyTabs"));
};
