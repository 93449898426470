var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { styled, Box } from "@mui/material";
import { InfoBar as OctupleInfoBar, } from "@eightfold.ai/octuple";
var InfoBar = function (props) {
    var CheckBoxContainer = styled(Box)({
        "--font-stack-full": "Avenir Book",
    });
    return (_jsx(CheckBoxContainer, __assign({ className: "info-bar", "data-testid": "InfoBar" }, { children: _jsx(OctupleInfoBar, { content: props.content, type: props.type, bordered: true, style: { padding: "16px", fontSize: "18px" } }) })));
};
export var DisappearingInfoBar = function (props) {
    var _a = useState(true), visible = _a[0], setVisible = _a[1];
    useEffect(function () {
        var id = setTimeout(function () {
            setVisible(false);
        }, 10000);
        return function () {
            clearTimeout(id);
        };
    }, []);
    return visible ? _jsx(InfoBar, __assign({}, props)) : _jsx(_Fragment, {});
};
export default InfoBar;
