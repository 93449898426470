// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.URde0aYf2ZKGbtHOVNlg .dq7MNGeTxoJMCXiURrd9 {
  background: transparent !important;
  border: none !important;
}

.URde0aYf2ZKGbtHOVNlg .aD1ExCu0_4IR0Icd94oH {
  border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/PipelineStatusCards/PipelineStatusCards.module.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,uBAAA;AACF;;AAEA;EACE,uBAAA;AACF","sourcesContent":[".customProgressWrapper .progress-inner_WocghUW {\n  background: transparent !important;\n  border: none !important;\n}\n\n.customProgressWrapper .bordered_YwuTdxo {\n  border: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customProgressWrapper": `URde0aYf2ZKGbtHOVNlg`,
	"progress-inner_WocghUW": `dq7MNGeTxoJMCXiURrd9`,
	"bordered_YwuTdxo": `aD1ExCu0_4IR0Icd94oH`
};
export default ___CSS_LOADER_EXPORT___;
