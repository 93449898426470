var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useEffect, useState, } from "react";
import BackToButton from "../../components/BackToButton";
import SecondaryMainHeading from "../../components/SecondMainHeading/SecondMainHeading";
import { useParams } from "react-router-dom";
import { useGetNineBox, updateNineBoxSettingsQuery, useGetAvailableNineBoxes, useGetUserId, } from "../../store/api/core";
import { Form, Icon, IconName, IconSize } from "@eightfold.ai/octuple";
import { createPortal } from "react-dom";
import Dialog from "../../components/Dialog";
import { NineBox, ReadOnlyNineBox } from "../../components/NineBox/NineBox";
import trStyles from "../../components/TalentReview/TalentReview.module.scss";
import { getRoute } from "../../routes/getAllRoutes";
import { emptyFilters, setFilters } from "../../providers/FilterProvider";
import { IconPathName } from "../../IconPath/mdi";
import EditNineBoxForm from "./EditNineBoxForm";
import styles from "../../components/TalentReview/TalentReview.module.scss";
import { FilterRestrictionContext } from "../../providers/FilterRestrictionProvider";
import Tooltip from "../../components/Tooltip";
import { TooltipPlacement } from "../../components/Tooltip/Tooltip";
var FilterClearingBackToButton = function (_a) {
    var url = _a.url, title = _a.title;
    var clearFilters = function () { return setFilters(emptyFilters); };
    var setRestrictions = useContext(FilterRestrictionContext).setRestrictions;
    var handleFilters = function () {
        clearFilters();
        setRestrictions({});
    };
    return _jsx(BackToButton, { title: title, url: url, onClick: handleFilters });
};
export var TalentReviewNineBoxBase = function (_a) {
    var children = _a.children, secondaryHeadingTitle = _a.secondaryHeadingTitle, backToButton = _a.backToButton;
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryMainHeading, { backToButton: backToButton, role: "All", level: "All", "data-testid": "SecondaryHeading", secondaryHeadingTitle: secondaryHeadingTitle }), children] }));
};
var ConfirmUpdateModal = function () {
    return (_jsx("div", { children: "Updating the filters may result in removing some leaders who have already had their placement saved." }));
};
var EditConfirmHeader = function () {
    return (_jsxs("div", __assign({ className: styles.editConfirmHeaderWrapper }, { children: [_jsx("div", __assign({ className: styles.editConfirmHeaderIcon }, { children: _jsx(Icon, { size: IconSize.Large, path: IconPathName.mdiCritical }) })), _jsx("div", { children: "Some saved leader placements may be lost" })] })));
};
var EditConfirmModal = function (_a) {
    var onComplete = _a.onComplete, onClose = _a.onClose, isPending = _a.isPending, isConfirmDialogVisible = _a.isConfirmDialogVisible;
    return (_jsx(Dialog, { header: _jsx(EditConfirmHeader, {}), body: _jsx(ConfirmUpdateModal, {}), buttons: {
            okButtonProps: {
                text: "Continue anyway",
                onClick: onComplete,
                loading: isPending,
            },
            cancelButtonProps: {
                text: "Cancel",
            },
        }, visible: isConfirmDialogVisible, onClose: onClose }));
};
var EditNineBoxDialog = function (props) {
    var form = Form.useForm()[0];
    var _a = useState(false), disableSaveBtn = _a[0], setDisableSaveBtn = _a[1];
    var updateNineBoxName = updateNineBoxSettingsQuery({
        substitutions: { id: props.boxId },
        invalidates: [
            { queryKey: ["{ninebox}/nine-box/".concat(props.boxId)] },
            { queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] },
        ],
    });
    var _b = useState(""), formError = _b[0], setFormError = _b[1];
    var _c = useState(""), errorClass = _c[0], setErrorClass = _c[1];
    var changeDisableButtonStatus = function (status) {
        return setDisableSaveBtn(status);
    };
    var resetEditFormValues = function () {
        setFormError("");
        setErrorClass("");
    };
    var compareBoxName = function () {
        resetEditFormValues();
    };
    React.useEffect(function () {
        compareBoxName();
    }, []);
    useEffect(function () {
        setFormError(props.formErrorMessage || "");
        setErrorClass(props.errorClassMessage || "");
    }, [props.formErrorMessage, props.errorClassMessage]);
    var onSaveClick = function () {
        form.submit();
    };
    var extractValues = function (filterArray) {
        return (filterArray === null || filterArray === void 0 ? void 0 : filterArray.map(function (item) { return item.value; })) || [];
    };
    var onFormFinish = function (formValues, filters) {
        var allFiltersValues = {
            businessUnits: extractValues(filters.business),
            functions: extractValues(filters.function),
            subFunctions: extractValues(filters.subFunction),
            levels: extractValues(filters.level),
            locations: extractValues(filters.location1),
            locationCountries: extractValues(filters.location2),
            divisions: extractValues(filters.division),
            roles: extractValues(filters.role),
            managers: extractValues(filters.manager),
        };
        if (props.field == "boxName" && !props.isReadOnly) {
            updateNineBoxName.mutate(__assign(__assign({}, (props.initialValue != formValues.name && {
                boxName: formValues.name,
            })), { filters: __assign({}, allFiltersValues) }), {
                onSuccess: function () {
                    setFormError("");
                    setErrorClass("");
                    props.onClose();
                },
                onError: function (data) {
                    var _a;
                    if (data.message == "400") {
                        setFormError((_a = data.cause) === null || _a === void 0 ? void 0 : _a.boxName[0]);
                        setErrorClass(trStyles.formError);
                    }
                },
            });
        }
        if (props.field == "versionName") {
            updateNineBoxName.mutate({
                versionName: formValues.name,
                filters: __assign({}, allFiltersValues),
            }, {
                onSuccess: function () { return props.onClose(); },
                onError: function (data) {
                    var _a;
                    if (data.message == "400") {
                        setFormError((_a = data.cause) === null || _a === void 0 ? void 0 : _a.versionName[0]);
                        setErrorClass(trStyles.formError);
                    }
                },
            });
        }
    };
    var boxNameHeader = "Edit 9 Box Name";
    var versionNameHeader = "Edit Version Name";
    return (_jsx(Dialog, { header: props.field == "boxName" ? boxNameHeader : versionNameHeader, body: _jsx(EditNineBoxForm, { initialValue: props.initialValue, boxId: props.boxId, setFormError: setFormError, setErrorClass: setErrorClass, onFormFinish: onFormFinish, isReadOnly: props.isReadOnly, getData: props.getData, openConfirmDialog: props.openConfirmDialog, form: form, field: "boxName", errorClass: errorClass, formError: formError, resetEditFormValues: resetEditFormValues, compareBoxName: compareBoxName, changeDisableButtonStatus: changeDisableButtonStatus }), buttons: {
            okButtonProps: {
                text: "Save",
                onClick: onSaveClick,
                disabled: disableSaveBtn,
            },
            cancelButtonProps: {
                text: "Cancel",
            },
        }, visible: props.visible, onClose: props.onClose }));
};
var ReadOnlyBoxNameTitle = function (_a) {
    var _b;
    var setVisible = _a.setVisible, setInitialVersionName = _a.setInitialVersionName, id = _a.id;
    var nineBoxDetails = useGetNineBox({ id: id });
    var _c = ((_b = nineBoxDetails === null || nineBoxDetails === void 0 ? void 0 : nineBoxDetails.data) === null || _b === void 0 ? void 0 : _b.data) || {}, versionName = _c.versionName, userPermissions = _c.userPermissions;
    var userId = useGetUserId();
    var actionPermissionsStatus = !(userPermissions === null || userPermissions === void 0 ? void 0 : userPermissions.some(function (user) { return user.userId === userId && user.permissions === "edit"; }));
    var handlePencilClick = function () {
        setVisible(true);
        setInitialVersionName(versionName);
    };
    return (_jsxs("div", __assign({ className: styles.editPencilIconContainer }, { children: [_jsx("div", { children: versionName || "" }), actionPermissionsStatus ? (_jsx(Tooltip, __assign({ content: "Requires edit access", placement: TooltipPlacement.Bottom }, { children: _jsx("div", __assign({ style: {
                        pointerEvents: "none",
                        opacity: "0.5",
                        backgroundColor: "#f0f0f0",
                    } }, { children: _jsx(Icon, { path: IconName.mdiPencil }) })) }))) : (_jsx("div", __assign({ className: styles.editPencilIcon, onClick: handlePencilClick, "data-testid": "editPencil" }, { children: _jsx(Icon, { path: IconName.mdiPencil }) })))] })));
};
var ReadOnlyBackToButton = function (_a) {
    var _b, _c, _d, _e;
    var id = _a.id;
    var nineBoxDetails = useGetNineBox({ id: id });
    var boxId = (((_b = nineBoxDetails === null || nineBoxDetails === void 0 ? void 0 : nineBoxDetails.data) === null || _b === void 0 ? void 0 : _b.data) || {}).boxId;
    var availableBoxes = useGetAvailableNineBoxes({ boxId: boxId }, { shouldSkipCall: function () { return !boxId; } });
    var currentVersionId = (_e = (_d = (_c = availableBoxes === null || availableBoxes === void 0 ? void 0 : availableBoxes.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.id;
    return currentVersionId ? (_jsx(FilterClearingBackToButton, { title: "Current Version", url: getRoute("TalentReviewNineBox").replace(":id", currentVersionId) })) : (_jsx(_Fragment, {}));
};
export var ReadOnlyTalentReviewNineBoxPage = function () {
    var id = useParams().id;
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    var _b = useState(""), initialVersionName = _b[0], setInitialVersionName = _b[1];
    var _c = useContext(FilterRestrictionContext), restrictions = _c.restrictions, setRestrictions = _c.setRestrictions;
    useEffect(function () {
        return function () {
            if (JSON.stringify(restrictions) != JSON.stringify({})) {
                setRestrictions({});
            }
        };
    }, []);
    return (_jsxs(TalentReviewNineBoxBase, __assign({ backToButton: _jsx(ReadOnlyBackToButton, { id: id }), secondaryHeadingTitle: _jsx(ReadOnlyBoxNameTitle, { setVisible: setVisible, setInitialVersionName: setInitialVersionName, id: id }) }, { children: [_jsx(ReadOnlyNineBox, { id: id }), visible &&
                createPortal(_jsx(EditNineBoxDialog, { field: "versionName", visible: visible, boxId: id, initialValue: initialVersionName, onClose: function () { return setVisible(false); }, isReadOnly: true }), document.body)] })));
};
var BoxNameTitle = function (_a) {
    var _b;
    var id = _a.id, setInitialBoxName = _a.setInitialBoxName, setVisible = _a.setVisible;
    var nineBoxDetails = useGetNineBox({ id: id });
    var _c = ((_b = nineBoxDetails === null || nineBoxDetails === void 0 ? void 0 : nineBoxDetails.data) === null || _b === void 0 ? void 0 : _b.data) || {}, boxName = _c.boxName, userPermissions = _c.userPermissions;
    var userId = useGetUserId();
    var actionPermissionsStatus = !(userPermissions === null || userPermissions === void 0 ? void 0 : userPermissions.some(function (user) { return user.userId === userId && user.permissions === "edit"; }));
    var handlePencilClick = function () {
        setInitialBoxName(boxName || "");
        setVisible(true);
    };
    return (_jsxs("div", __assign({ className: styles.editPencilIconContainer }, { children: [_jsx("div", { children: boxName || "" }), actionPermissionsStatus ? (_jsx(Tooltip, __assign({ content: "Requires edit access", placement: TooltipPlacement.Bottom }, { children: _jsx("div", __assign({ style: {
                        pointerEvents: "none",
                        opacity: "0.5",
                        backgroundColor: "#f0f0f0",
                    }, "data-testid": "editPencil", onClick: handlePencilClick }, { children: _jsx(Icon, { path: IconName.mdiPencil }) })) }))) : (_jsx("div", __assign({ className: styles.editPencilIcon, onClick: handlePencilClick, "data-testid": "editPencil" }, { children: _jsx(Icon, { path: IconName.mdiPencil }) })))] })));
};
export var TalentReviewNineBoxPage = function () {
    var id = useParams().id;
    var _a = useState(""), initialBoxName = _a[0], setInitialBoxName = _a[1];
    var _b = useState(false), isEditFormDialog = _b[0], setIsEditFormDialog = _b[1];
    var _c = useState(false), isConfirmDialog = _c[0], setIsConfirmDialog = _c[1];
    var _d = useState(), formData = _d[0], setFormData = _d[1];
    var _e = useContext(FilterRestrictionContext), restrictions = _e.restrictions, setRestrictions = _e.setRestrictions;
    useEffect(function () {
        return function () {
            if (JSON.stringify(restrictions) != JSON.stringify({})) {
                setRestrictions({});
            }
        };
    }, []);
    var _f = useState(""), formConfirmError = _f[0], setFormConfirmError = _f[1];
    var _g = useState(""), formConfirmErrorClass = _g[0], setFormConfirmErrorClass = _g[1];
    var resetConfirmErrosMessages = function () {
        setFormConfirmError("");
        setFormConfirmErrorClass("");
    };
    var extractValues = function (filterArray) {
        return (filterArray === null || filterArray === void 0 ? void 0 : filterArray.map(function (item) { return item.value; })) || [];
    };
    var getData = function (data) {
        console.log("data", data);
        setFormData(data);
    };
    var onClose = function () {
        setIsConfirmDialog(false);
    };
    var openConfirmDialog = function () {
        setIsConfirmDialog(true);
    };
    var updateNineBoxName = updateNineBoxSettingsQuery({
        substitutions: { id: id },
        invalidates: [
            { queryKey: ["{ninebox}/nine-box/".concat(id)] },
            { queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] },
        ],
    });
    var onFormFinish = function (_a) {
        var name = _a.name, filters = _a.filters;
        updateNineBoxName.mutate(__assign(__assign({}, (initialBoxName != name && { boxName: name })), { filters: {
                businessUnits: extractValues(filters === null || filters === void 0 ? void 0 : filters.business),
                functions: extractValues(filters === null || filters === void 0 ? void 0 : filters.function),
                subFunctions: extractValues(filters === null || filters === void 0 ? void 0 : filters.subFunction),
                levels: extractValues(filters === null || filters === void 0 ? void 0 : filters.level),
                locations: extractValues(filters === null || filters === void 0 ? void 0 : filters.location1),
                locationCountries: extractValues(filters === null || filters === void 0 ? void 0 : filters.location2),
                divisions: extractValues(filters === null || filters === void 0 ? void 0 : filters.division),
                roles: extractValues(filters === null || filters === void 0 ? void 0 : filters.role),
                managers: extractValues(filters === null || filters === void 0 ? void 0 : filters.manager),
            } }), {
            onSuccess: function () {
                setIsEditFormDialog(false);
                setIsConfirmDialog(false);
                resetConfirmErrosMessages();
                onClose();
            },
            onError: function (data) {
                var _a;
                setIsConfirmDialog(false);
                if (data.message == "400") {
                    setFormConfirmError((_a = data.cause) === null || _a === void 0 ? void 0 : _a.boxName[0]);
                    setFormConfirmErrorClass(trStyles.formError);
                }
            },
        });
    };
    var onComplete = function () {
        if (formData) {
            onFormFinish(formData);
        }
    };
    return (_jsxs(TalentReviewNineBoxBase, __assign({ backToButton: _jsx(FilterClearingBackToButton, { title: "Talent Review", url: getRoute("DashboardTalentReview") }), secondaryHeadingTitle: _jsx(BoxNameTitle, { setVisible: setIsEditFormDialog, setInitialBoxName: setInitialBoxName, id: id }) }, { children: [_jsx(NineBox, { id: id }), isEditFormDialog &&
                createPortal(_jsx(EditNineBoxDialog, { field: "boxName", visible: isEditFormDialog, boxId: id, initialValue: initialBoxName, onClose: function () {
                        setIsEditFormDialog(false);
                        resetConfirmErrosMessages();
                    }, isReadOnly: false, openConfirmDialog: openConfirmDialog, getData: getData, formErrorMessage: formConfirmError, errorClassMessage: formConfirmErrorClass }), document.body), isConfirmDialog &&
                createPortal(_jsx(EditConfirmModal, { onComplete: onComplete, onClose: onClose, isPending: updateNineBoxName.isPending, isConfirmDialogVisible: isConfirmDialog }), document.body)] })));
};
