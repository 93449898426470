var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from "react";
import { Box } from "@mui/material";
import DiversitySettings from "../../../components/DiversitySettings";
import SecondaryMainHeading from "../../../components/SecondMainHeading/SecondMainHeading";
import BackToButton from "../../../components/BackToButton";
import { getRoute } from "../../../routes/getAllRoutes";
var DiversitySettingsPage = function () {
    var ethnicDiversityRef = useRef();
    var genderDiversityRef = useRef();
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryMainHeading, { backToButton: _jsx(BackToButton, { title: "Demographics", url: getRoute("DashboardDiversity") }), role: "All", level: "All", "data-testid": "SecondaryHeading", secondaryHeadingTitle: "Demographics Settings" }), _jsx(Box, __assign({ sx: { marginTop: "36px" } }, { children: _jsx(DiversitySettings, { ethnicDiversityRef: ethnicDiversityRef, genderDiversityRef: genderDiversityRef }) }))] }));
};
export default DiversitySettingsPage;
