var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Icon } from "@eightfold.ai/octuple";
import { IconPathName } from "../../IconPath/mdi";
import styles from "./TalentReview.module.scss";
var NineBoxErrorMessage = function () {
    return (_jsxs("div", __assign({ className: styles.nineBoxLeaderCountErrorMsgContainer }, { children: [_jsx("span", __assign({ className: styles.exclamationIconContainer }, { children: _jsx("span", __assign({ className: styles.exclamationIcon }, { children: _jsx(Icon, { path: IconPathName.mdiExclamation }) })) })), _jsxs("div", __assign({ className: styles.leaderCountErrorMessage }, { children: ["You need to have at least 2 leaders to create a 9 Box.", _jsx("div", { children: "Please update your filters." })] }))] })));
};
export default NineBoxErrorMessage;
