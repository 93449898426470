var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Progress } from "@eightfold.ai/octuple";
import { Box, styled } from "@mui/material";
import RightArrow from "../RightArrow";
import { useNavigate } from "../../hooks/navigate";
import { getRoute } from "../../routes/getAllRoutes";
import styles from "./Diversity.module.scss";
var MainContainer = styled(Box)({
    margin: "24px 0",
    borderRadius: "25px",
    border: "1px solid #D9DCE1",
    padding: "24px",
});
var ProgressWrapper = styled(Box)({
    marginLeft: "24px",
    padding: "24px 24px 32px 24px",
    borderRadius: "16px",
    backgroundColor: "#F6F7F8",
    lineHeight: "20px",
    flex: 1,
});
var MainTitle = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: "20px",
        fontWeight: "400",
        fontFamily: theme.typography.fontFamily + " Heavy",
        color: "#1A212E",
    });
});
var MainDesc = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "400",
        fontFamily: theme.typography.fontFamily + " Book",
        color: "#0E2E26",
    });
});
var ProgressLabel = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: "14px",
        fontWeight: "400",
        fontFamily: theme.typography.fontFamily + " Book",
        color: "#0E2E26",
        marginRight: "4px",
        textWrap: "nowrap",
    });
});
var DescriptionBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
});
var SuccessionPipelines = function (_a) {
    var successPipelines = _a.successPipelines;
    var total = successPipelines.total, inPipeline = successPipelines.inPipeline, notInPipeline = successPipelines.notInPipeline;
    var inPipelineCal = (inPipeline / total) * 100;
    var notInPipelineCal = (notInPipeline / total) * 100;
    var inPipelinePer = Number.isNaN(inPipelineCal) ? 0 : inPipelineCal;
    var notInPipelinePer = Number.isNaN(notInPipelineCal)
        ? 0
        : notInPipelineCal;
    var navigate = useNavigate();
    var handleOnPipelineClick = function (key) {
        navigate(getRoute(key));
    };
    return (_jsx(MainContainer, { children: _jsxs(Box, __assign({ sx: { display: "flex" } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1 } }, { children: [_jsxs(MainTitle, { children: ["All leaders (", total, ")"] }), _jsxs(MainDesc, { children: [inPipelinePer.toFixed(0), "% are in a pipeline"] })] })), _jsxs(ProgressWrapper, { children: [_jsxs(DescriptionBox, __assign({ onClick: function () { return handleOnPipelineClick("OnPipeline"); } }, { children: [_jsxs(ProgressLabel, { children: [inPipelinePer.toFixed(0), "% are in a pipeline (", inPipeline, " leader", inPipeline != 1 ? "s" : "", ")"] }), _jsx(RightArrow, {})] })), _jsx("div", __assign({ className: styles.customProgressWrapper }, { children: _jsx(Progress, { showLabels: false, percent: inPipelinePer }) }))] }), _jsxs(ProgressWrapper, { children: [_jsxs(DescriptionBox, __assign({ onClick: function () { return handleOnPipelineClick("NotOnPipeline"); } }, { children: [_jsxs(ProgressLabel, { children: [notInPipelinePer.toFixed(0), "% are NOT in a pipeline (", notInPipeline, " leader", notInPipeline != 1 ? "s" : "", ")"] }), _jsx(RightArrow, {})] })), _jsx("style", { children: "\n                 .".concat(styles.customProgressWrapper, " .progress-inner_WocghUW {\n                   background: transparent !important;\n                   border: none !important;\n                 }\n                 .").concat(styles.customProgressWrapper, " .bordered_YwuTdxo {\n                   border: 1px solid #8A9FAB !important;\n                 }\n               ") }), _jsx("div", __assign({ className: styles.customProgressWrapper }, { children: _jsx(Progress, { showLabels: false, strokeColor: {
                                    from: "#7BE4F4",
                                    to: "#7BE4F4",
                                }, percent: notInPipelinePer }) }))] })] })) }));
};
export default SuccessionPipelines;
