var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Autocomplete as MuiAutocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import CheckBox from "../../components/CheckBox";
import { SelectorSize } from "@eightfold.ai/octuple";
import styles from "./Autocomplete.module.scss";
export var AutocompleteOptionStyles = {
    backgroundColor: "inherit",
    borderRadius: "50px",
    marginTop: "5px",
    fontFamily: "Avenir Book",
    fontSize: "18px",
    color: "#1A212E",
};
export var AutocompleteChipProps = {
    sx: {
        backgroundColor: "#e0f7fa",
        border: "1px solid #146DA6",
        fontSize: "14px",
        color: "#1A212E",
        borderRadius: "8px",
    },
    deleteIcon: _jsx(CloseOutlined, { className: styles.closeOutlined }),
};
var Autocomplete = function (_a) {
    var options = _a.options, onSelect = _a.onSelect, label = _a.label, resetTrigger = _a.resetTrigger, _b = _a.initialValue, initialValue = _b === void 0 ? [] : _b, disabled = _a.disabled, placeholder = _a.placeholder, isOptionEqualToValue = _a.isOptionEqualToValue;
    var _c = React.useState(initialValue), selectedOptions = _c[0], setSelectedOptions = _c[1];
    var _d = React.useState(false), isFocused = _d[0], setIsFocused = _d[1];
    React.useEffect(function () {
        if (resetTrigger) {
            setSelectedOptions([]);
            onSelect([]);
            setIsFocused(false);
        }
    }, [resetTrigger, onSelect]);
    var filterOptions = (options !== null && options !== void 0 ? options : [])
        .filter(function (option) { return option === null || option === void 0 ? void 0 : option.value; })
        .sort(function (_a, _b) {
        var a = _a.label;
        var b = _b.label;
        return (a > b ? 1 : -1);
    });
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: styles.autocompleteMainContainer }, { children: [_jsx("label", __assign({ className: styles.autocompleteLabel }, { children: label })), _jsx(MuiAutocomplete, { multiple: true, options: filterOptions, "data-testid": "Autocomplete", limitTags: 2, onChange: function (event, newValue) {
                        setSelectedOptions(newValue);
                        onSelect(newValue);
                    }, ListboxProps: {
                        onScroll: function (event) {
                            var listboxNode = event.currentTarget;
                            if (listboxNode.scrollTop === 0) {
                                console.log("Top of the list reached");
                            }
                        },
                    }, disablePortal: true, disableCloseOnSelect: true, sx: { padding: "8px 0" }, value: selectedOptions, disabled: disabled, renderInput: function (params) {
                        return (_jsx(TextField, __assign({}, params, { placeholder: placeholder
                                ? placeholder
                                : (selectedOptions === null || selectedOptions === void 0 ? void 0 : selectedOptions.length) === 0 && !isFocused
                                    ? "All"
                                    : "", sx: {
                                border: "1px solid #69717F",
                                borderRadius: "8px",
                                "& .MuiOutlinedInput-root": {
                                    padding: "0px 0px",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                },
                                "& .MuiOutlinedInput-notchedOutline legend": {
                                    border: "none",
                                },
                                backgroundColor: "var(--background-color)",
                            } })));
                    }, isOptionEqualToValue: isOptionEqualToValue ||
                        (function (option, value) { return option.value === value.value; }), renderOption: function (props, option) {
                        var key = props.key, optionProps = __rest(props, ["key"]);
                        return (_jsx("div", { children: _jsxs("li", __assign({}, optionProps, { style: AutocompleteOptionStyles }, { children: [_jsx("div", __assign({ style: { position: "relative", top: "-2px" } }, { children: _jsx(CheckBox, { size: SelectorSize.Medium, checked: function () { return !!(props === null || props === void 0 ? void 0 : props["aria-selected"]); } }) })), _jsx("span", { children: option.label })] })) }, "".concat(key, "-").concat(option.value)));
                    }, ChipProps: AutocompleteChipProps })] })) }));
};
export default Autocomplete;
