import { useGetSkillsCategoryQuery, useGetStatementLibrariesQuery, useGetClientModelsQuery, } from "../../store/api/core";
import { PillVariant } from "../Pill/Pill";
export var VeryImportantSkillTag = {
    text: "Very Important",
    variant: PillVariant.Warning,
};
export var CriticalSkillTag = {
    text: "Critical",
    variant: PillVariant.Error,
};
var importanceRatingMappings = {
    "Very Important": VeryImportantSkillTag,
    Critical: CriticalSkillTag,
};
export var useClientModelsLookup = function (_a) {
    var skillGroup = _a.skillGroup, clientId = _a.clientId;
    return useGetClientModelsQuery({ clientId: clientId, skillGroupName: skillGroup });
};
export var useStatementLibrariesLookup = function (_a) {
    var skillGroup = _a.skillGroup, clientId = _a.clientId;
    return useGetStatementLibrariesQuery({ clientId: clientId, skillGroup: skillGroup });
};
function useLookup(_a) {
    var idKey = _a.idKey, useQuery = _a.useQuery;
    return function (_a) {
        var _b;
        var clientId = _a.clientId, skillGroup = _a.skillGroup;
        var statementLibrariesResponse = useQuery({
            clientId: clientId,
            skillGroup: skillGroup,
        });
        var statementLibrariesResponseData = (_b = statementLibrariesResponse === null || statementLibrariesResponse === void 0 ? void 0 : statementLibrariesResponse.data) === null || _b === void 0 ? void 0 : _b.data;
        if (!statementLibrariesResponseData) {
            return [];
        }
        var statementLibraryMap = (statementLibrariesResponseData || [])
            .filter(function (model) { return model.clientId == (clientId || "Master"); })
            .reduce(function (acc, library) {
            acc[library[idKey]] = {
                name: (library === null || library === void 0 ? void 0 : library.name) || "",
                description: (library === null || library === void 0 ? void 0 : library.description) || "",
                impactModuleName: (library === null || library === void 0 ? void 0 : library.impactModuleName) || "",
            };
            return acc;
        }, {});
        return statementLibraryMap;
    };
}
export var useSuccessProfilesDataSource = function (_a) {
    var useLookupQuery = _a.useLookupQuery, lookupKey = _a.lookupKey;
    return function (clientId, roleProfileId, skillGroup) {
        var _a, _b, _c;
        var result = { description: "", skills: [] };
        var skillCategoryResponse = useGetSkillsCategoryQuery({
            clientId: clientId,
            roleProfileId: roleProfileId,
        });
        var skillCategoryResponseData = (_b = (_a = skillCategoryResponse === null || skillCategoryResponse === void 0 ? void 0 : skillCategoryResponse.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0];
        var lookupMap = useLookupQuery({ clientId: clientId, skillGroup: skillGroup });
        if (!skillCategoryResponseData || Object.keys(lookupMap).length < 1) {
            return result;
        }
        var modules = {};
        var skillGroupData = (_c = skillCategoryResponseData.skillGroups.filter(function (group) { return group.name === skillGroup; })) === null || _c === void 0 ? void 0 : _c[0];
        skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.skills.forEach(function (skill) {
            var lookups = lookupMap[skill === null || skill === void 0 ? void 0 : skill[lookupKey]];
            var impactModule = (lookups === null || lookups === void 0 ? void 0 : lookups.impactModuleName) || "";
            if (!modules[impactModule]) {
                modules[impactModule] = [];
            }
            if (Object.keys(skill).length > 0) {
                modules[impactModule].push({
                    name: (lookups === null || lookups === void 0 ? void 0 : lookups.name) || "",
                    description: (lookups === null || lookups === void 0 ? void 0 : lookups.description) || "",
                    id: skill.id,
                    benchmark: skill.benchmark || 0,
                    tag: skill.importanceRating == "Very Important" ||
                        skill.importanceRating == "Critical"
                        ? importanceRatingMappings[skill.importanceRating]
                        : undefined,
                });
            }
        });
        Object.keys(modules)
            .sort(function (a, b) { return a.localeCompare(b); })
            .forEach(function (key) {
            result.skills.push({
                summary: key,
                skills: modules[key].sort(function (a, b) { var _a, _b; return (_a = a.name) === null || _a === void 0 ? void 0 : _a.localeCompare((_b = b.name) === null || _b === void 0 ? void 0 : _b.toString()); }),
            });
        });
        result["description"] = skillGroupData === null || skillGroupData === void 0 ? void 0 : skillGroupData.description;
        return result;
    };
};
export var useImpactDataSource = useSuccessProfilesDataSource({
    lookupKey: "statementLibraryUUID",
    useLookupQuery: useLookup({
        idKey: "statementLibraryUUID",
        useQuery: useStatementLibrariesLookup,
    }),
});
export var useCapabilitiesPotentialDataSource = useSuccessProfilesDataSource({
    lookupKey: "clientModelId",
    useLookupQuery: useLookup({
        idKey: "id",
        useQuery: useClientModelsLookup,
    }),
});
