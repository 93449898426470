var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createRef, useContext, useEffect } from "react";
import ResetButton from "../ResetButton/ResetButton";
import { Box } from "@mui/material";
import { FilterResetEventName, MultiSelectFilter } from "./MultiSelectFilter";
import { emptyFilters, filterListeners, getFilters, setFilters, updateFilters, } from "../../providers/FilterProvider";
import { getAttributeType, getClientId, LeaderAttribute, useGetLookupsQuery, useGetLookupsFiltersQuery, useGetDataFiltersQuery, useGetClientPlatformQuery, } from "../../store/api/core";
import { FloatingSpinner } from "../SpinnerAndErrorer/SpinnerAndErrorer";
import { isObject, memoize } from "lodash";
import { Stack } from "@eightfold.ai/octuple";
import { SearchRoleNameFilter } from "./SearchRoleNameFilter";
import { Additional } from "./Additional";
import { useClient } from "../../hooks/client";
import { FilterRestrictionContext } from "../../providers/FilterRestrictionProvider";
import { useLookupField } from "../LookupField/LookupField";
export var AvailableFilters;
(function (AvailableFilters) {
    AvailableFilters["BusinessUnit"] = "businessUnit";
    AvailableFilters["Function"] = "function";
    AvailableFilters["FunctionSuccessProfile"] = "functionSuccessProfile";
    AvailableFilters["SubFunction"] = "subFunction";
    AvailableFilters["SubFunctionSuccessProfile"] = "subFunctionSuccessProfile";
    AvailableFilters["Level"] = "level";
    AvailableFilters["LevelSuccessProfile"] = "levelSuccessProfile";
    AvailableFilters["Location"] = "officeLocation";
    AvailableFilters["Manager"] = "manager";
    AvailableFilters["RoleName"] = "roleName";
    AvailableFilters["Reset"] = "reset";
    AvailableFilters["Spinner"] = "spinner";
    AvailableFilters["RetentionRisk"] = "retentionRisk";
    AvailableFilters["CareerInterest"] = "careerInterests";
    AvailableFilters["AssessmentSource"] = "assessmentSource";
    AvailableFilters["Age"] = "age";
    AvailableFilters["DerailerRisk"] = "derailerRisk";
    AvailableFilters["RelocationPreference"] = "willingToRelocate";
    AvailableFilters["Gender"] = "gender";
    AvailableFilters["Ethnicity"] = "race";
    AvailableFilters["NineBox"] = "9box";
    AvailableFilters["Location2"] = "locationCountry";
    AvailableFilters["Division"] = "division";
    AvailableFilters["RoleProfileId"] = "role";
    AvailableFilters["NineBoxRating"] = "9boxRatings";
})(AvailableFilters || (AvailableFilters = {}));
var AdditionalAvailableFilters = new Set([
    AvailableFilters.RetentionRisk,
    AvailableFilters.CareerInterest,
    AvailableFilters.AssessmentSource,
    AvailableFilters.Age,
    AvailableFilters.DerailerRisk,
    AvailableFilters.RelocationPreference,
    AvailableFilters.Gender,
    AvailableFilters.Ethnicity,
    AvailableFilters.NineBox,
    AvailableFilters.NineBoxRating,
]);
var hasAdditionalFilters = function (filters) {
    return Array.from(filters).some(function (filter) { return AdditionalAvailableFilters.has(filter); });
};
/* eslint-disable  @typescript-eslint/no-explicit-any */
var getFunctionMap = function (data, current, result) {
    if (Array.isArray(data)) {
        return data.forEach(function (value) {
            getFunctionMap(value, current, result);
        });
    }
    if (isObject(data) &&
        (data === null || data === void 0 ? void 0 : data.code) &&
        getAttributeType(data === null || data === void 0 ? void 0 : data.code) == LeaderAttribute.Level) {
        result.levelLabels[data.code] = data.fieldValue;
    }
    if (isObject(data) &&
        (data === null || data === void 0 ? void 0 : data.code) &&
        getAttributeType(data === null || data === void 0 ? void 0 : data.code) == LeaderAttribute.PrimaryRole) {
        result.functionLabels[data.code] = data.fieldValue;
    }
    if (isObject(data) &&
        (data === null || data === void 0 ? void 0 : data.code) &&
        getAttributeType(data === null || data === void 0 ? void 0 : data.code) == LeaderAttribute.PrimaryRole &&
        (data === null || data === void 0 ? void 0 : data.subFunctions)) {
        return getFunctionMap(Object.values(data.subFunctions), data.code, result);
    }
    if (isObject(data) &&
        (data === null || data === void 0 ? void 0 : data.code) &&
        getAttributeType(data === null || data === void 0 ? void 0 : data.code) == LeaderAttribute.SecondaryRole &&
        getAttributeType(current) == LeaderAttribute.PrimaryRole) {
        result.functionFilters[data.code] = current;
        result.subFunctionLabels[data.code] = data.fieldValue;
        if (!result.subFunctionFilters[current]) {
            result.subFunctionFilters[current] = new Set();
        }
        result.subFunctionFilters[current].add(data.code);
    }
    if (isObject(data)) {
        getFunctionMap(Object.values(data), current, result);
    }
};
/* eslint-enable  @typescript-eslint/no-explicit-any */
var getMemoizedFunctionMap = memoize(function (data) {
    var result = {
        functionFilters: {},
        subFunctionFilters: {},
        functionLabels: {},
        subFunctionLabels: {},
        levelLabels: {},
    };
    getFunctionMap(data, undefined, result);
    return result;
});
export var useFunctionMap = function (_a) {
    var _b;
    var clientId = _a.clientId;
    var client = useClient();
    var clientIdToUse = clientId || client.clientId;
    var response = useGetLookupsQuery({ clientId: clientIdToUse }, { shouldSkipCall: function () { return !clientIdToUse; } });
    return getMemoizedFunctionMap((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data[0]);
};
export var DefaultFilterSet = new Set([
    AvailableFilters.Location,
    AvailableFilters.Location2,
    AvailableFilters.BusinessUnit,
    AvailableFilters.Division,
    AvailableFilters.Function,
    AvailableFilters.SubFunction,
    AvailableFilters.RoleProfileId,
    AvailableFilters.Level,
    AvailableFilters.Manager,
    AvailableFilters.Reset,
    AvailableFilters.Spinner,
].concat(Array.from(AdditionalAvailableFilters)));
export var LandingPageFilters = new Set([
    AvailableFilters.Location,
    AvailableFilters.Location2,
    AvailableFilters.BusinessUnit,
    AvailableFilters.Division,
    AvailableFilters.RoleProfileId,
    AvailableFilters.Spinner,
    AvailableFilters.Reset,
    AvailableFilters.NineBox,
].concat(Array.from(AdditionalAvailableFilters)));
export var SuccessProfileFilterSet = new Set([
    AvailableFilters.FunctionSuccessProfile,
    AvailableFilters.SubFunctionSuccessProfile,
    AvailableFilters.LevelSuccessProfile,
    AvailableFilters.RoleName,
    AvailableFilters.Spinner,
    AvailableFilters.Reset,
]);
var useFunctionOptionFilter = function (useOptions) {
    return function (_a) {
        var _b, _c, _d, _e, _f;
        var clientId = _a.clientId, filters = _a.filters;
        var response = (useOptions || useGetDataFiltersQuery)({
            clientId: clientId,
            filters: __assign(__assign({}, filters), { functionFilter: [], subFunctionFilter: [] }),
        });
        var functionMap = useFunctionMap({ clientId: clientId });
        if (response.isFetching) {
            return response;
        }
        if (response.isError) {
            return response;
        }
        if (!(filters === null || filters === void 0 ? void 0 : filters.subFunctionFilter) || filters.subFunctionFilter.length == 0) {
            return {
                data: {
                    data: __assign(__assign({}, (((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) || {})), { function: (_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d["function"].map(function (_a) {
                            var _b;
                            var value = _a.value, label = _a.label;
                            return ({
                                value: value,
                                label: ((_b = functionMap === null || functionMap === void 0 ? void 0 : functionMap.functionLabels) === null || _b === void 0 ? void 0 : _b[value]) || label,
                            });
                        }) }),
                },
            };
        }
        if (!((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.data)) {
            return response;
        }
        var inFilters = ((_f = getFilters()) === null || _f === void 0 ? void 0 : _f.functionFilter) || [];
        inFilters.sort();
        var functionFilter = Array.from(new Set(filters.subFunctionFilter
            .map(function (value) { var _a; return (_a = functionMap === null || functionMap === void 0 ? void 0 : functionMap.functionFilters) === null || _a === void 0 ? void 0 : _a[value]; })
            .filter(function (value) { return value; })
            .concat(inFilters)));
        functionFilter.sort();
        if (JSON.stringify(functionFilter) != JSON.stringify(inFilters)) {
            updateFilters({ functionFilter: functionFilter });
        }
        var copy = { data: { data: {} } };
        copy.data.data = Object.entries(response.data.data).reduce(function (acc, _a) {
            var key = _a[0], value = _a[1];
            if (key == "function") {
                value = value.map(function (val) {
                    return {
                        value: val.value,
                        label: functionMap.functionLabels[val.value],
                    };
                });
            }
            acc[key] = value;
            return acc;
        }, {});
        return copy;
    };
};
var useFunctionRestrictions = function (entity) { return function () {
    var restrictions = useContext(FilterRestrictionContext).restrictions;
    var lookups = useLookupField({ clientId: getClientId() });
    var map = useFunctionMap({ clientId: getClientId() });
    var copy = Object.entries(restrictions || {}).reduce(function (current, _a) {
        var _b;
        var key = _a[0], value = _a[1];
        return (__assign(__assign({}, current), (_b = {}, _b[key] = new Set(Array.from(value || new Set())), _b)));
    }, {});
    Array.from((restrictions === null || restrictions === void 0 ? void 0 : restrictions["subFunctions"]) || [])
        .reduce(function (current, value) { return __spreadArray(__spreadArray([], current, true), (map.functionFilters[value] ? [map.functionFilters[value]] : []), true); }, [])
        .forEach(function (value) {
        var _a;
        (_a = copy === null || copy === void 0 ? void 0 : copy["functions"]) === null || _a === void 0 ? void 0 : _a.add(value);
    });
    Array.from((restrictions === null || restrictions === void 0 ? void 0 : restrictions["functions"]) || [])
        .reduce(function (current, value) {
        var _a;
        return __spreadArray(__spreadArray([], current, true), (((_a = map.subFunctionFilters[value]) === null || _a === void 0 ? void 0 : _a.size)
            ? Array.from(map.subFunctionFilters[value])
            : []), true);
    }, [])
        .forEach(function (value) {
        var _a;
        (_a = copy === null || copy === void 0 ? void 0 : copy["subFunctions"]) === null || _a === void 0 ? void 0 : _a.add(value);
    });
    return { entity: entity, restrictions: copy, lookups: lookups };
}; };
var FunctionFilter = function (_a) {
    var _b;
    var useDataSource = _a.useDataSource;
    var useRestrictions = useFunctionRestrictions("functions");
    var response = useGetLookupsQuery({ clientId: getClientId() });
    var functionMap = getMemoizedFunctionMap((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data[0]);
    var useOptions = useFunctionOptionFilter(useDataSource);
    var eventListener = function (_a, allNext) {
        var previous = _a.subFunctionFilter, settable = _a.functionFilter;
        var next = allNext.subFunctionFilter;
        var subChanges = (next || []).filter(function (value) { return !(previous === null || previous === void 0 ? void 0 : previous.includes(value)); });
        var changes = subChanges.map(function (value) { return functionMap.functionFilters[value]; });
        if (!changes.every(function (value) { return settable === null || settable === void 0 ? void 0 : settable.includes(value); })) {
            var mergedFunctions_1 = Array.from(new Set(changes.concat(settable || [])));
            if (!mergedFunctions_1.every(function (value) { return settable === null || settable === void 0 ? void 0 : settable.includes(value); }) ||
                mergedFunctions_1.length != (settable === null || settable === void 0 ? void 0 : settable.length)) {
                setTimeout(function () {
                    setFilters(__assign(__assign({}, allNext), { subFunctionFilter: next, functionFilter: mergedFunctions_1 }));
                }, 0);
            }
        }
    };
    useEffect(function () {
        filterListeners.add(eventListener);
        return function () {
            filterListeners.delete(eventListener);
        };
    }, [response]);
    return (_jsx(MultiSelectFilter, { label: "Function", entity: "function", filterEntity: "functionFilter", useRestrictions: useRestrictions, useOptionsQuery: useOptions }));
};
var useSubFunctionOptionFilter = function (useOptions) {
    return function (_a) {
        var _b, _c, _d, _e;
        var clientId = _a.clientId, filters = _a.filters;
        var response = (useOptions || useGetDataFiltersQuery)({
            clientId: clientId,
            filters: filters,
        });
        var functionMap = useFunctionMap({ clientId: clientId });
        if ((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) {
            response.data.data = Object.entries(response.data.data).reduce(function (acc, _a) {
                var key = _a[0], value = _a[1];
                if (key == "sub_function") {
                    value = value.map(function (val) {
                        return {
                            value: val.value,
                            label: functionMap.subFunctionLabels[val.value],
                        };
                    });
                }
                acc[key] = value;
                return acc;
            }, {});
        }
        var subFunctions = ((_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d["sub_function"]) || [];
        if (response.isFetching) {
            return response;
        }
        if (response.isError) {
            return response;
        }
        if (!(filters === null || filters === void 0 ? void 0 : filters.functionFilter) || filters.functionFilter.length == 0) {
            return response;
        }
        if (!((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.data)) {
            return response;
        }
        var copy = JSON.parse(JSON.stringify(response));
        copy.data.data.sub_function = subFunctions.filter(function (subFunction) {
            return filters.functionFilter.includes(functionMap.functionFilters[subFunction.value]);
        });
        return copy;
    };
};
var SubFunctionFilter = function (_a) {
    var _b;
    var useDataSource = _a.useDataSource;
    var useRestrictions = useFunctionRestrictions("subFunctions");
    var useOptions = useSubFunctionOptionFilter(useDataSource, useRestrictions);
    var response = useGetLookupsQuery({ clientId: getClientId() });
    var functionMap = getMemoizedFunctionMap((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data[0]);
    var filterRereader = createRef(null);
    var eventListener = function (_a, allNext) {
        var previous = _a.functionFilter, settable = _a.subFunctionFilter;
        var next = allNext.functionFilter;
        var removedFunctions = (previous || []).filter(function (value) { return !(next === null || next === void 0 ? void 0 : next.includes(value)); });
        var removedSubFunctions = removedFunctions.reduce(function (current, value) {
            return current.concat(Array.from(functionMap.subFunctionFilters[value] || []));
        }, []);
        var newSettable = Array.from(new Set(__spreadArray([], (settable || []), true).filter(function (value) { return !removedSubFunctions.includes(value); })));
        if (!newSettable.every(function (value) { return (settable || []).includes(value); }) ||
            newSettable.length != (settable === null || settable === void 0 ? void 0 : settable.length)) {
            setTimeout(function () {
                setFilters(__assign(__assign({}, allNext), { functionFilter: next, subFunctionFilter: newSettable }));
            }, 0);
        }
    };
    useEffect(function () {
        filterListeners.add(eventListener);
        return function () {
            filterListeners.delete(eventListener);
        };
    }, [response]);
    return (_jsx(MultiSelectFilter, { label: "Sub-Function", entity: "sub_function", filterEntity: "subFunctionFilter", filterRereader: filterRereader, useRestrictions: useRestrictions, useOptionsQuery: useOptions }));
};
var useLevelOptionFilter = function (_a) {
    var _b;
    var clientId = _a.clientId, filters = _a.filters;
    var response = useGetDataFiltersQuery({
        clientId: clientId,
        filters: filters,
    });
    var functionMap = useFunctionMap({ clientId: clientId });
    if ((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) {
        response.data.data = Object.entries(response.data.data).reduce(function (acc, _a) {
            var key = _a[0], value = _a[1];
            if (key == "level") {
                value = value.map(function (val) {
                    return {
                        value: val.value,
                        label: functionMap.levelLabels[val.value],
                    };
                });
            }
            acc[key] = value;
            return acc;
        }, {});
    }
    return response;
};
var useFilterComponents = function (_a) {
    var clearFilters = _a.clearFilters;
    var defaultClearFilters = function () {
        setFilters(emptyFilters);
    };
    var filterOrder = [
        {
            key: AvailableFilters.Location,
            implementation: (_jsx(Box, { children: _jsx(MultiSelectFilter, { entity: "location", label: "Location 1" }) }, AvailableFilters.Location)),
        },
        {
            key: AvailableFilters.Location2,
            implementation: (_jsx(Box, { children: _jsx(MultiSelectFilter, { entity: "location_2", label: "Location 2" }) }, AvailableFilters.Location2)),
        },
        {
            key: AvailableFilters.BusinessUnit,
            implementation: (_jsx(MultiSelectFilter, { entity: "business_unit", label: "Business Unit" }, AvailableFilters.BusinessUnit)),
        },
        {
            key: AvailableFilters.Division,
            implementation: (_jsx(MultiSelectFilter, { entity: "division", label: "Division" }, AvailableFilters.Division)),
        },
        {
            key: AvailableFilters.Function,
            implementation: _jsx(FunctionFilter, {}, AvailableFilters.Function),
        },
        {
            key: AvailableFilters.FunctionSuccessProfile,
            implementation: (_jsx(FunctionFilter, { useDataSource: useGetLookupsFiltersQuery }, AvailableFilters.FunctionSuccessProfile)),
        },
        {
            key: AvailableFilters.SubFunction,
            implementation: _jsx(SubFunctionFilter, {}, AvailableFilters.SubFunction),
        },
        {
            key: AvailableFilters.SubFunctionSuccessProfile,
            implementation: (_jsx(SubFunctionFilter, { useDataSource: useGetLookupsFiltersQuery }, AvailableFilters.SubFunctionSuccessProfile)),
        },
        {
            key: AvailableFilters.RoleProfileId,
            implementation: (_jsx(MultiSelectFilter, { entity: "roles", filterEntity: "roleProfileId", label: "Role" }, AvailableFilters.RoleProfileId)),
        },
        {
            key: AvailableFilters.Level,
            implementation: (_jsx(MultiSelectFilter, { label: "Level", entity: "level", useOptionsQuery: useLevelOptionFilter }, AvailableFilters.Level)),
        },
        {
            key: AvailableFilters.LevelSuccessProfile,
            implementation: (_jsx(MultiSelectFilter, { label: "Level", entity: "level", useOptionsQuery: useGetLookupsFiltersQuery }, AvailableFilters.LevelSuccessProfile)),
        },
        {
            key: AvailableFilters.Manager,
            implementation: (_jsx(MultiSelectFilter, { label: "Manager", entity: "manager" }, AvailableFilters.Manager)),
        },
        {
            key: AvailableFilters.RoleName,
            implementation: (_jsx(Box, { children: _jsx(SearchRoleNameFilter, {}) }, AvailableFilters.RoleName)),
        },
        {
            key: AvailableFilters.Spinner,
            implementation: _jsx(FloatingSpinner, {}, AvailableFilters.Spinner),
        },
        {
            key: AvailableFilters.Reset,
            implementation: (_jsx(ResetButton, { label: "Reset filters", onClick: function () {
                    (clearFilters || defaultClearFilters)();
                    setTimeout(function () {
                        window.dispatchEvent(new CustomEvent(FilterResetEventName, {}));
                    }, 0);
                } }, AvailableFilters.Reset)),
        },
    ];
    return filterOrder;
};
var RightSideFilters = new Set([
    AvailableFilters.Spinner,
    AvailableFilters.Reset,
]);
var isRightSideFilter = function (_a) {
    var key = _a.key;
    return RightSideFilters.has(key);
};
var isLeftSideFilter = function (_a) {
    var key = _a.key;
    return !isRightSideFilter({ key: key });
};
var implementationalize = function (_a) {
    var implementation = _a.implementation;
    return implementation;
};
var appendTrailer = function (implementations, trailer) {
    if (!trailer) {
        return implementations;
    }
    if (Array.isArray(trailer)) {
        return implementations.concat(trailer);
    }
    return implementations.concat([trailer]);
};
export var Filters = function (_a) {
    var availableFilters = _a.availableFilters, clearFilters = _a.clearFilters, leftTrailer = _a.leftTrailer, rightTrailer = _a.rightTrailer;
    var filters = useFilterComponents({ clearFilters: clearFilters });
    var filtersVisibleValues = useGetClientPlatformQuery({});
    var presentFilters = new Set(availableFilters || DefaultFilterSet);
    var isUsedFilter = function (_a) {
        var _b, _c, _d, _e;
        var key = _a.key;
        var filters = (_e = (_d = (_c = (_b = filtersVisibleValues === null || filtersVisibleValues === void 0 ? void 0 : filtersVisibleValues.data) === null || _b === void 0 ? void 0 : _b.data[0]) === null || _c === void 0 ? void 0 : _c.applications) === null || _d === void 0 ? void 0 : _d.orgDashboard) === null || _e === void 0 ? void 0 : _e.filters;
        if (!presentFilters.has(key))
            return false;
        var isFilterVisible = (filters === null || filters === void 0 ? void 0 : filters[key]) !== false;
        // Special handling: If subFunction is true, show both subFunction and function filters
        if ((filters === null || filters === void 0 ? void 0 : filters.subFunction) === true) {
            if (key === "function" || key === "subFunction") {
                return true;
            }
        }
        return isFilterVisible;
    };
    var left = appendTrailer(filters
        .filter(isUsedFilter)
        .filter(isLeftSideFilter)
        .map(implementationalize), leftTrailer).filter(function (v) { return v; });
    var right = appendTrailer(filters
        .filter(isUsedFilter)
        .filter(isRightSideFilter)
        .map(implementationalize)
        .filter(function (v) { return v; })
        .concat(hasAdditionalFilters(availableFilters || new Set())
        ? [_jsx(Additional, { filters: availableFilters || new Set() })]
        : []), rightTrailer);
    return (_jsxs(Stack, __assign({ fullWidth: true, direction: "horizontal", justify: "space-between" }, { children: [_jsx(Stack, __assign({ direction: "horizontal", flexGap: "s", wrap: "wrap" }, { children: left })), _jsx(Stack, __assign({ direction: "horizontal", flexGap: "s", wrap: "nowrap" }, { children: right }))] })));
};
