var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { createElement } from "react";
import Pill from "../../Pill/Pill";
import styles from "./Skill.module.scss";
import Tooltip from "../../Tooltip";
import { TooltipPlacement } from "../../Tooltip/Tooltip";
export var ProgressStepper = function (props) {
    var boldLabelIndex = Math.floor((benchmarkSteps.length - 1) * ((props.currentStep - 1) / 4));
    return (_jsxs("div", __assign({ className: styles.skillStepperContainer }, { children: [_jsx("div", __assign({ className: styles.skillStepperWrapper }, { children: _jsxs("div", __assign({ className: styles.progressBarSteps }, { children: [_jsx("div", { className: styles.progressBar, style: { width: "".concat(25 * (props.currentStep - 1), "%") } }), _jsx("div", __assign({ style: { position: "relative" } }, { children: _jsx(Tooltip, __assign({ content: props.currentStep.toFixed(2), placement: TooltipPlacement.Left }, { children: _jsx("div", { className: styles.progressBarPointer }) })) })), _jsx("div", { className: styles.progressBarGap, style: { left: "26.8%" } }), _jsx("div", { className: styles.progressBarGap, style: { left: "51.8%" } }), _jsx("div", { className: styles.progressBarGap, style: { left: "76.8%" } })] })) })), _jsx("div", __assign({ className: styles.progressBarLabelContainer }, { children: benchmarkSteps.map(function (label, index) { return (_jsx("div", __assign({ style: {
                        fontWeight: boldLabelIndex == index ? "bold" : "normal",
                        fontFamily: boldLabelIndex == index ? "Avenir Heavy" : "Avenir Book",
                        marginRight: index === benchmarkSteps.length - 1 ? "20px" : "0",
                    } }, { children: label }), label)); }) }))] })));
};
var SkillName = function (props) {
    return _jsx("div", __assign({ className: styles.skillName }, { children: props.name }));
};
var SkillDescription = function (props) {
    return _jsx("div", __assign({ className: styles.skillDescription }, { children: props.description }));
};
var benchmarkSteps = [
    "Undeveloped",
    "Emerging",
    "Proficient",
    "Advanced",
    "Expert",
];
var Skill = function (props) {
    var highlightLabel = props.benchmark < 2.0
        ? "Undeveloped"
        : props.benchmark >= 2.0 && props.benchmark < 3.0
            ? "Emerging"
            : props.benchmark >= 3.0 && props.benchmark < 4.0
                ? "Proficient"
                : props.benchmark >= 4.0 && props.benchmark < 5.0
                    ? "Advanced"
                    : "Expert";
    return (_jsxs("div", __assign({ className: styles.skillContainer }, { children: [_jsxs("div", __assign({ className: styles.skillLeftContainer }, { children: [_jsxs("div", __assign({ className: styles.skillNameContainer }, { children: [_jsx(SkillName, { name: "".concat(props.name).concat(props.benchmark > -1
                                    ? " (".concat(props.benchmark.toFixed(2), " - ").concat(highlightLabel, ")")
                                    : "") }), props.tag && (_jsx(Pill, { text: props.tag.text, variant: props.tag.variant }))] })), _jsx(SkillDescription, { description: props.description })] })), createElement(props.progressStepperFactory, {
                currentStep: props.benchmark,
                id: props.id,
                clientEdits: props.clientEdits,
            })] })));
};
export default Skill;
