// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i3k3c7GAvQ_W7beL0eAX {
  display: flex;
  width: 230px;
  height: 48px;
  padding: 10px 16px;
  gap: 10px;
}

.HLTF3H8T3w_y6KtPP2lA {
  font-family: Avenir, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-color-90);
}

.EBTRgSeyaTZOSySFPMCB {
  font-family: Avenir, sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-color-90);
}

.Of8h_Qhtb8nQXuayUEDI {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.RR0tMg1GGKAOX2aubQuj {
  padding-top: 2px;
}

.VOavgHXYkdAWvCejMRQx {
  transform: scaleX(-1);
}

.FBgbVpavsvTqKVtzyZAG {
  scale: 0.8;
}

.PIKbC9VrqCjiITVUCUEY {
  display: flex;
  flex-direction: row;
  gap: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/NineBox/icons/iconBox.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;AACF;;AAEA;EACE,+BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,2BAAA;AACF;;AAEA;EACE,+BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AACF","sourcesContent":[".iconBox {\n  display: flex;\n  width: 230px;\n  height: 48px;\n  padding: 10px 16px;\n  gap: 10px;\n}\n\n.regularFont {\n  font-family: Avenir, sans-serif;\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  color: var(--grey-color-90);\n}\n\n.heavyFont {\n  font-family: Avenir, sans-serif;\n  font-weight: 800;\n  font-size: 16px;\n  line-height: 24px;\n  color: var(--grey-color-90);\n}\n\n.name {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.inlineName {\n  padding-top: 2px;\n}\n\n.unbadge {\n  transform: scaleX(-1);\n}\n\n.inlineIcon {\n  scale: 0.8;\n}\n\n.inlineIconBox {\n  display: flex;\n  flex-direction: row;\n  gap: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBox": `i3k3c7GAvQ_W7beL0eAX`,
	"regularFont": `HLTF3H8T3w_y6KtPP2lA`,
	"heavyFont": `EBTRgSeyaTZOSySFPMCB`,
	"name": `Of8h_Qhtb8nQXuayUEDI`,
	"inlineName": `RR0tMg1GGKAOX2aubQuj`,
	"unbadge": `VOavgHXYkdAWvCejMRQx`,
	"inlineIcon": `FBgbVpavsvTqKVtzyZAG`,
	"inlineIconBox": `PIKbC9VrqCjiITVUCUEY`
};
export default ___CSS_LOADER_EXPORT___;
