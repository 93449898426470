var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useGetClientDetailsQuery, updateClientDetailsQuery, useGetDataFiltersQuery, } from "../../store/api/core";
import SpinnerAndErrorer, { willHold, } from "../SpinnerAndErrorer/SpinnerAndErrorer";
import OverviewTabs from "../OveriewTabs/OverviewTabs";
import InfoBar from "../InfoBar/InfoBar";
import CheckBox from "../CheckBox/CheckBox";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import Table, { ValidSortDirections } from "../Table/Table";
import { useClient } from "../../hooks/client";
var TabContent = function (tabProps) {
    var title = tabProps.title, locations = tabProps.locations, diversityOptions = tabProps.diversityOptions, diversityDataRef = tabProps.diversityDataRef, setDiversityData = tabProps.setDiversityData, tableHeaders = tabProps.tableHeaders;
    var TabBody = styled(Box)({
        margin: "32px",
    });
    var onChange = function (country, selectedValues) {
        var newDiversityData = JSON.parse(JSON.stringify(diversityDataRef.current));
        var valueToUpdate = newDiversityData.find(function (diversity) { return diversity.country === country; });
        if (valueToUpdate && valueToUpdate.value) {
            valueToUpdate.value = selectedValues;
        }
        else {
            newDiversityData.push({
                country: country,
                value: selectedValues,
            });
        }
        setDiversityData(newDiversityData);
    };
    var formatters = [
        function (location) { return location; },
        function (diversity) {
            return (_jsx(Box, { children: _jsx(Dropdown, { options: diversityOptions, multiple: true, initial: (diversity === null || diversity === void 0 ? void 0 : diversity.value) || [], inactiveLabel: "All", onChangeMultiple: function (selected) {
                        onChange(diversity.country, selected);
                    }, renderCounter: function (options, selected) {
                        var _a;
                        if (!options ||
                            options.length === 0 ||
                            !selected ||
                            selected.length === 0) {
                            return "None";
                        }
                        var firstSelected = ((_a = options.find(function (_a) {
                            var value = _a.value;
                            return value === selected[0];
                        })) === null || _a === void 0 ? void 0 : _a.label) || "";
                        if (selected.length === 1) {
                            return firstSelected;
                        }
                        if (selected.length > 1) {
                            return "".concat(firstSelected, " +").concat(selected.length - 1);
                        }
                        return "None";
                    } }) }));
        },
    ];
    var tableData = locations.map(function (location) {
        return [
            location === null || location === void 0 ? void 0 : location.label,
            diversityDataRef.current.find(function (diversity) { return diversity.country === location.value; }) || { country: location.value, value: [] },
        ];
    });
    return (_jsxs(TabBody, __assign({ sx: { "td:first-child": { width: "30%" } } }, { children: [_jsx(Typography, __assign({ variant: "h4", sx: { paddingBottom: "24px" } }, { children: title })), _jsx(Table, { showingInfo: function () {
                    return "".concat(locations.length, " location").concat(locations.length !== 1 ? "s" : "");
                }, data: tableData, formatters: formatters, headers: tableHeaders, defaultSortColumnIndex: 0, defaultSortDirection: ValidSortDirections.ASC, unsortableColumns: [1] })] })));
};
export var DiversitySettings = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var ethnicDiversityRef = _a.ethnicDiversityRef, genderDiversityRef = _a.genderDiversityRef;
    var clientId = useClient().clientId;
    var _l = useState(Math.random()), rerenderKey = _l[0], setRerenderKey = _l[1];
    var clientDetailsResponse = useGetClientDetailsQuery({});
    var updateClientDetails = updateClientDetailsQuery({
        invalidates: [
            { queryKey: ["{dashboard}/{ClientId}/client-details"] },
            { queryKey: ["{dashboard}/{ClientId}/leaders"] },
        ],
    });
    var filterOptionsResponse = useGetDataFiltersQuery({
        clientId: clientId,
    });
    var _m = useState(false), dataLoaded = _m[0], setDataLoaded = _m[1];
    var _o = useState(((_b = clientDetailsResponse.data) === null || _b === void 0 ? void 0 : _b.data.hide_gender_and_ethnicity) || false), hideGenderAndEthnicity = _o[0], setHideGenderAndEthnicity = _o[1];
    if (ethnicDiversityRef.current == undefined) {
        ethnicDiversityRef.current =
            ((_c = clientDetailsResponse.data) === null || _c === void 0 ? void 0 : _c.data.ethnic_diversity) || [];
    }
    var setEthnicDiversity = function (value) {
        ethnicDiversityRef.current = value;
    };
    if (genderDiversityRef.current == undefined) {
        genderDiversityRef.current =
            ((_d = clientDetailsResponse.data) === null || _d === void 0 ? void 0 : _d.data.gender_diversity) || [];
    }
    var setGenderDiversity = function (value) {
        genderDiversityRef.current = value;
    };
    var buildOptions = function (data) {
        if (!data) {
            return [];
        }
        return data
            .filter(function (element) {
            return (element !== undefined &&
                element !== null &&
                typeof element !== "string");
        })
            .sort(function (a, b) { return a.value.localeCompare(b.value); })
            .map(function (item) { return ({ value: item.value, label: item.label }); });
    };
    var locationCountries = buildOptions((_e = filterOptionsResponse.data) === null || _e === void 0 ? void 0 : _e.data.location_2);
    var genderOptions = buildOptions((_f = filterOptionsResponse.data) === null || _f === void 0 ? void 0 : _f.data.gender);
    var ethnicityOptions = buildOptions((_g = filterOptionsResponse.data) === null || _g === void 0 ? void 0 : _g.data.ethnicity);
    // Remove any diversity data not matching the location country or dropdown options
    var removeBadDiversityData = function (diversityData, locationCountries, diversityOptions) {
        diversityData.forEach(function (data) {
            data.value = data.value.filter(function (value) {
                return diversityOptions.some(function (option) { return option.value === value; });
            });
        });
        return diversityData.filter(function (data) {
            if (data.country === "") {
                // Don't filter out the "All" option
                return true;
            }
            return locationCountries.some(function (country) { return country.value === data.country || data.country === ""; });
        });
    };
    if (!dataLoaded &&
        !willHold([clientDetailsResponse, filterOptionsResponse])) {
        setHideGenderAndEthnicity(((_h = clientDetailsResponse.data) === null || _h === void 0 ? void 0 : _h.data.hide_gender_and_ethnicity) || false);
        setEthnicDiversity(removeBadDiversityData(((_j = clientDetailsResponse.data) === null || _j === void 0 ? void 0 : _j.data.ethnic_diversity) || [], locationCountries, ethnicityOptions));
        setGenderDiversity(removeBadDiversityData(((_k = clientDetailsResponse.data) === null || _k === void 0 ? void 0 : _k.data.gender_diversity) || [], locationCountries, genderOptions));
        setDataLoaded(true);
    }
    var tabs = [{ label: "Ethnicity" }, { label: "Gender" }];
    var data = useMemo(function () { return [
        TabContent({
            title: "Ethnicity",
            locations: locationCountries,
            diversityOptions: ethnicityOptions,
            diversityDataRef: ethnicDiversityRef,
            setDiversityData: setEthnicDiversity,
            tableHeaders: ["Location", "Diverse Ethnicities"],
        }),
        TabContent({
            title: "Gender",
            locations: [{ label: "All", value: "" }],
            diversityOptions: genderOptions,
            diversityDataRef: genderDiversityRef,
            setDiversityData: setGenderDiversity,
            tableHeaders: ["Location", "Diverse Genders"],
        }),
    ]; }, [dataLoaded, rerenderKey]);
    var TopContainer = styled(Box)({
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        paddingBottom: "60px",
        margin: "0 32px",
    });
    var InfoBarContainer = styled(Box)({
        margin: "0 32px 24px 32px",
    });
    return (_jsxs(_Fragment, { children: [updateClientDetails.isError && (_jsx(InfoBarContainer, { children: _jsx(InfoBar, { content: "Unable to save changes", type: "disruptive" }) })), updateClientDetails.isSuccess && (_jsx(InfoBarContainer, { children: _jsx(InfoBar, { content: "Changes saved", type: "positive" }) })), _jsxs(SpinnerAndErrorer, __assign({ conditions: [clientDetailsResponse, filterOptionsResponse] }, { children: [_jsxs(TopContainer, __assign({ "data-testid": "DiversitySettings" }, { children: [_jsx(CheckBox, { label: "Hide ethnicity and gender data for individual leaders", value: "hide_gender_and_ethnicity", checked: hideGenderAndEthnicity, onChange: function (event) {
                                    setHideGenderAndEthnicity(event.target.checked);
                                } }), _jsx(Button, { text: "Save changes", loading: updateClientDetails.isPending, onClick: function () {
                                    updateClientDetails.mutate({
                                        hide_gender_and_ethnicity: hideGenderAndEthnicity,
                                        gender_diversity: genderDiversityRef.current,
                                        ethnic_diversity: ethnicDiversityRef.current,
                                    });
                                } })] })), _jsx(OverviewTabs, { onChange: function () {
                            setRerenderKey(Math.random());
                        }, tabs: tabs, data: data, selectedTab: 0, sx: { paddingTop: "0", width: "100%", margin: "0" } })] }))] }));
};
export default DiversitySettings;
