var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { TabbedFilteredOverviewPage } from "../../components/FilteredOverviewPage/FilteredOverviewPage";
import { useGetClientPlatformQuery, useGetStatementLibrariesQuery, SkillGroup as ImpactSkillGroup, } from "../../store/api/core";
import { hasPermissionExist } from "../../store/api/permissions";
import { getRoute } from "../../routes/getAllRoutes";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { Icon, IconName, SelectorSize } from "@eightfold.ai/octuple";
import { ButtonVariant, IconButton } from "../../components/Button/Button";
import CheckBox from "../../components/CheckBox";
import styles from "./SuccessProfilePage.module.scss";
import { FilterContext, updateFilters } from "../../providers/FilterProvider";
import { useClient } from "../../hooks/client";
import { Table } from "../../components/SuccessProfile/Table";
import { SuccessProfileFilterSet, useFunctionMap, } from "../../components/Filters/Filters";
import { debounce } from "lodash";
import { SuccessProfilesPopup } from "./SuccessProfilesPopup";
import { FilterResetEventName } from "../../components/Filters/MultiSelectFilter";
import { AutocompleteChipProps, AutocompleteOptionStyles, } from "../../components/Autocomplete/Autocomplete";
var MINIMUM_FOR_SEARCH = 3;
var debouncedStringSetter = debounce(function (value, setter) {
    setter(value);
}, 1000);
var SkillFilter = function (_a) {
    var _b;
    var clientId = _a.clientId;
    var _c = useState(Math.random()), rerenderKey = _c[0], setRerenderKey = _c[1];
    var _d = useState(""), value = _d[0], setValue = _d[1];
    var lookups = useFunctionMap({ clientId: clientId });
    var getFilters = useContext(FilterContext).getFilters;
    var _e = getFilters(), level = _e.level, functionFilter = _e.functionFilter, subFunctionFilter = _e.subFunctionFilter;
    var _f = useState(true), disabled = _f[0], setDisabled = _f[1];
    var _g = useState(""), name = _g[0], setName = _g[1];
    var subFunctions = (subFunctionFilter || []).concat((functionFilter || []).reduce(function (current, func) {
        var _a;
        return __spreadArray(__spreadArray([], current, true), ((subFunctionFilter || []).length !== 0 ||
            (subFunctionFilter || []).some(function (f) { var _a; return (_a = lookups[func]) === null || _a === void 0 ? void 0 : _a.subFunctionFilters.has(f); })
            ? []
            : Array.from(((_a = lookups === null || lookups === void 0 ? void 0 : lookups.subFunctionFilters) === null || _a === void 0 ? void 0 : _a[func]) || [])), true);
    }, []));
    useEffect(function () {
        var rerender = function () {
            setRerenderKey(Math.random());
        };
        window.addEventListener(FilterResetEventName, rerender);
        return function () {
            window.removeEventListener(FilterResetEventName, rerender);
        };
    }, []);
    var searching = name.length >= MINIMUM_FOR_SEARCH;
    var statementLibrariesResponse = useGetStatementLibrariesQuery(__assign(__assign({ clientId: clientId }, (name.length > 0 ? { name: name } : {})), { filters: { level: level, subFunctionFilter: subFunctions }, sort: ["name"], skillGroup: "".concat(ImpactSkillGroup.Organizational, ",").concat(ImpactSkillGroup.Functional) }), { shouldSkipCall: function () { return !searching || !clientId; } });
    var optionValues = ((_b = statementLibrariesResponse === null || statementLibrariesResponse === void 0 ? void 0 : statementLibrariesResponse.data) === null || _b === void 0 ? void 0 : _b.data) || [];
    var options = (optionValues === null || optionValues === void 0 ? void 0 : optionValues.map(function (_a) {
        var name = _a.name, id = _a.id;
        return ({
            id: id,
            name: name,
        });
    })) || [];
    var ableSetter = function () {
        setDisabled(false);
    };
    useEffect(function () {
        var id = setTimeout(ableSetter, 3000);
        return function () { return clearTimeout(id); };
    }, []);
    return (_jsx(Autocomplete, { inputValue: value, multiple: true, disabled: disabled, limitTags: 2, id: "SkillFilter", options: options, noOptionsText: name.length < MINIMUM_FOR_SEARCH &&
            "Please type at least ".concat(MINIMUM_FOR_SEARCH, " characters"), disableCloseOnSelect: true, getOptionLabel: function (option) { return option === null || option === void 0 ? void 0 : option.name; }, onChange: function (event, value) {
            updateFilters({ statement: (value === null || value === void 0 ? void 0 : value.map(function (_a) {
                    var id = _a.id;
                    return id;
                })) || [] });
        }, filterOptions: function (options) { return options; }, onClose: function () {
            setValue("");
            setName("");
        }, onInput: function (props) {
            setValue(props.target.value);
        }, renderInput: function (params) {
            var _a, _b;
            if (((_a = params === null || params === void 0 ? void 0 : params.inputProps) === null || _a === void 0 ? void 0 : _a.value) !== "") {
                debouncedStringSetter(((_b = params === null || params === void 0 ? void 0 : params.inputProps) === null || _b === void 0 ? void 0 : _b.value) || "", setName);
            }
            return (_jsx(TextField, __assign({}, params, { placeholder: "Ex: Strategic Transformation", sx: {
                    border: "1px solid #69717F",
                    borderRadius: "12px",
                    "& .MuiOutlinedInput-root": {
                        padding: "4px 10px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    width: "644px",
                    backgroundColor: "var(--background-color)",
                } })));
        }, renderOption: function (props, option) {
            var key = props.key, optionProps = __rest(props, ["key"]);
            return (_jsx("div", __assign({ className: styles.roleOption }, { children: _jsxs("li", __assign({}, optionProps, { style: AutocompleteOptionStyles }, { children: [_jsx("div", __assign({ style: { position: "relative", top: "-2px" } }, { children: _jsx(CheckBox, { size: SelectorSize.Medium, checked: function () { return !!(props === null || props === void 0 ? void 0 : props["aria-selected"]); } }) })), _jsx("span", __assign({ style: { textTransform: "capitalize" } }, { children: option.name }))] })) }), "".concat(key, "-").concat(option.id)));
        }, ChipProps: AutocompleteChipProps, componentsProps: {
            popupIndicator: {
                title: "",
            },
            popper: {
                modifiers: [
                    {
                        name: "flip",
                        enabled: false,
                    },
                    {
                        name: "preventOverflow",
                        enabled: false,
                    },
                ],
            },
        }, popupIcon: _jsx(IconButton, { iconPath: IconName.mdiChevronDown, variant: ButtonVariant.Neutral }), PaperComponent: React.useCallback(function (props) { return (_jsx(Paper, __assign({}, props, { className: styles.autocompleteSearchPaper }))); }, []), isOptionEqualToValue: function (option, value) { return option.id === value.id; } }, rerenderKey));
};
var SkillFilterShell = function (_a) {
    var children = _a.children;
    return (_jsxs("div", __assign({ className: styles.roleBasedContainer }, { children: [_jsxs("div", __assign({ className: styles.roleSpecificContentContainer }, { children: [_jsx("div", __assign({ className: styles.roleSpecificLabel }, { children: "Enter role-specific responsibilities and objectives to identify role matches." })), _jsx("div", __assign({ className: styles.roleSpecificDescription }, { children: "Examples include: HR budgeting, Market trend analysis, and Business unit operations management" }))] })), _jsxs("div", __assign({ className: styles.searchFilterLabelContainer }, { children: [_jsx("div", __assign({ className: styles.searchFilterLabel }, { children: "Role requirements and objectives" })), " ", children] }))] })));
};
export var SuccessProfilesPageImplementation = function () {
    var _a, _b, _c;
    var clientId = useClient().clientId;
    var platformClients = useGetClientPlatformQuery({});
    var clientName = ((_c = (_b = (_a = platformClients === null || platformClients === void 0 ? void 0 : platformClients.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.name) || "Your";
    var _d = useState(false), isPopupClicked = _d[0], setIsPopupClicked = _d[1];
    var availableFilters = new Set(__spreadArray([], Array.from(SuccessProfileFilterSet), true));
    var handleOpenPopup = function () {
        setIsPopupClicked(true);
    };
    var handleClosePopup = function () {
        setIsPopupClicked(false);
    };
    var SuccessProfileHomePageTitle = function () {
        return (_jsxs("div", __assign({ className: styles.successProfilesInfoIconContainer }, { children: [_jsx("div", { children: "Success Profiles" }), _jsx("div", __assign({ className: styles.successProfilesInfoIcon, onClick: handleOpenPopup }, { children: _jsx(Icon, { path: IconName.mdiInformationOutline }) }))] })));
    };
    return (_jsxs(_Fragment, { children: [_jsx(TabbedFilteredOverviewPage, { availableFilters: availableFilters, title: "Success Profiles", secondaryHeadingTitle: SuccessProfileHomePageTitle(), tabs: [
                    {
                        label: "".concat(clientName, " Library"),
                        hash: "client",
                        content: (_jsxs(_Fragment, { children: [_jsx(SkillFilterShell, { children: _jsx(SkillFilter, { clientId: clientId }) }), _jsx(Table, { clientId: clientId, route: getRoute("SuccessProfileRoleClient") })] })),
                    },
                    {
                        label: "Heidrick Library",
                        hash: "heidrick",
                        content: (_jsxs(_Fragment, { children: [_jsx(SkillFilterShell, { children: _jsx(SkillFilter, { clientId: "Master" }) }), _jsx(Table, { clientId: "Master", route: getRoute("SuccessProfileRoleHeidrick") })] })),
                    },
                ], backToButton: _jsx(_Fragment, {}) }), _jsx(SuccessProfilesPopup, { isOpen: isPopupClicked, handleClose: handleClosePopup })] }));
};
export var SuccessProfilesPage = function () {
    return hasPermissionExist("ORG_DASHBOARD_UI/SUCCESS_PROFILES") ? (_jsx(SuccessProfilesPageImplementation, {})) : (_jsx("div", { children: "You don't have success profiles setup." }));
};
