var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { useGetLeadershipOverviewQuery, useGetLeadershipScoresQuery, useGetLeadershipThresholdsQuery, useGetSkillGroupLookupsQuery, } from "../../store/api/core";
import { summaryLabelTypes } from "../../store/api/types/core";
import { ScoreTitles } from "../../common/action";
import { ScoreBreakdown } from "../../components/Summary/Summary";
import { Box, styled, Typography } from "@mui/material";
import "../../hd-ui-kit/HDTheme.css";
import { getRoute } from "../../routes/getAllRoutes";
import { useNavigate } from "../../hooks/navigate";
import { haveDiversityPages, haveTalentReviewPages, } from "../../common/featureFlags";
import OverviewTabs from "../../components/OveriewTabs/OverviewTabs";
import RoledSuccession from "../../components/RoledSuccession/RoledSuccession";
import LeaderedSuccession from "../../components/LeaderedSuccession/LeaderedSuccession";
import HeatmappedPage from "../../components/HeatmappedPage/HeatmappedPage";
import Diversity from "../../components/Diversity/Diversity";
import LeadershipRisk from "../../components/LeadershipRisk/LeadershipRisk";
import TalentReview from "../../components/TalentReview/TalentReview";
import { AiScoreLevelFunctionMatrix, CapabilityLevelFunctionMatrix, FunctionalLevelFunctionMatrix, HeidrickScoreLevelFunctionMatrix, OrganizationalLevelFunctionMatrix, OverallScoreLevelFunctionMatrix, PotentialLevelFunctionMatrix, ScoreTypes, starsScoreFormatter, percentageScoreFormatter, } from "../../components/LevelFunctionMatrix/Variants";
import Legend, { PercentageLegend } from "../../components/Legend/Legend";
import HomePageHeader from "../../components/HomePageHeader";
import { Status } from "../../common/action";
import StatusInfoPopUp from "../../components/StatusInfoPopUp/StatusInfoPopUp";
import StrengthInfoPopUp from "../../components/StrengthInfoPopup/StrengthInfoPopup";
import { usePermissions } from "../../hooks/permissions";
import { useScoreThreshold } from "../../hooks/threshold";
import { useClient } from "../../hooks/client";
var getAllAssessmentRoute = function (assessment) {
    return "".concat(getRoute("".concat(assessment, "All")));
};
var getOverviewTexts = function (scoreData) {
    var matcheds = [
        "organizational",
        "functional",
        "potential",
        "capability",
    ]
        .filter(function (score) {
        /* eslint-disable */
        return scoreData[score] == scoreData.overall;
    }
    /* eslint-enable */
    )
        .map(function (score) { return ScoreTitles[score]; });
    var matchedTexts = matcheds.length == 0
        ? "scores"
        : matcheds.length == 1
            ? matcheds[0]
            : matcheds.length === 2
                ? "".concat(matcheds[0], " and ").concat(matcheds[1])
                : matcheds
                    .map(function (score, index) {
                    return index == matcheds.length - 1
                        ? ", and ".concat(score)
                        : index == 0
                            ? score
                            : ", ".concat(score);
                })
                    .join("");
    return ({
        watch: "Monitor your organization's ".concat(matchedTexts || "", ". ") +
            "The heat map below will help you identify development opportunities across " +
            "Leadership Summary.",
        act: "Evaluate your organization's ".concat(matchedTexts || "", ". ") +
            "The heat map below will help you identify development opportunities across " +
            "Leadership Summary.",
        maintain: "Your organization's ".concat(matchedTexts || "", " ") +
            "are strong right now. The heat map below will help you " +
            "identify development opportunities across Leadership Summary.",
        nodata: "error with data",
    }[scoreData.overall] || "Error with overall score");
};
var toOneDecimal = function (value) {
    return (Math.round(value * 10) / 10).toFixed(1).toString();
};
var StarsLegendComponent = function (_a) {
    var scoreType = _a.scoreType, label = _a.label, scoreKey = _a.scoreKey;
    var _b = useScoreThreshold({
        scoreType: scoreType,
        useThreshold: useGetLeadershipThresholdsQuery,
        scoreKey: scoreKey,
        useScores: useGetLeadershipScoresQuery,
    }), low = _b[0], high = _b[1], action = _b[2];
    return (_jsx(Box, __assign({ sx: { position: "relative", top: "4px" } }, { children: _jsx(Legend, { label: label, action: action, legendItems: [
                {
                    action: Status.Act,
                    displayName: "Act",
                    range: "( < ".concat(toOneDecimal(low), " )"),
                    actualScoreMin: 0.0,
                    actualScoreMax: low,
                },
                {
                    action: Status.Watch,
                    displayName: "Watch",
                    range: "( \u2265 ".concat(toOneDecimal(low), "; < ").concat(toOneDecimal(high), " )"),
                    actualScoreMin: low,
                    actualScoreMax: high,
                },
                {
                    action: Status.Maintain,
                    displayName: "Maintain",
                    range: "( \u2265 ".concat(toOneDecimal(high), " )"),
                    actualScoreMin: high,
                    actualScoreMax: 5.0,
                },
            ], descriptionPopup: _jsx(StatusInfoPopUp, { description: "Scores range from 0 to 5 or 0% to 100% based on type of metric selected.", popupText: {
                    act: "Overall, leaders have significant gaps against multiple role requirements" +
                        ". Recommend immediate action to address greatest gaps and risk areas.",
                    watch: "Leaders generally have small to moderate gaps against some role requirements" +
                        ". Recommend closely monitoring and/or proactively managing areas of highest risk.",
                    maintain: "Leaders are generally meeting or exceeding most role requirements. " +
                        "Recommend leveraging key strengths while noting any potential areas of opportunity.",
                } }), isAssessment: false }) })));
};
var descriptionPopup = (_jsx(StatusInfoPopUp, { description: "Scores range from 0 to 5 or 0% to 100% based on type of metric selected.", popupText: {
        act: "A high number of leaders exhibit notable gaps in meeting several role requirements" +
            ". Recommend immediate action to target greatest gaps and risk areas.",
        watch: "A meaningful number of leaders exhibit gaps in meeting some role requirements. " +
            "Recommend close monitoring and/or proactive management of greatest gaps and risk areas.",
        maintain: "The majority of leaders are meeting role requirements in most areas. " +
            "Recommend identifying key areas of strength and any gaps requiring monitoring.",
    } }));
var toggledHeatmaps = [
    {
        label: summaryLabelTypes.Overall,
        implementation: _jsx(OverallScoreLevelFunctionMatrix, {}),
        legend: (_jsx(StarsLegendComponent, { scoreType: "Match", label: summaryLabelTypes.Overall, scoreKey: ScoreTypes.Overall })),
        scoreKey: ScoreTypes.Overall,
        scoreFormatter: starsScoreFormatter,
        description: "Average Match Score for leaders, combining overall assessment results (Heidrick Score) " +
            "and AI score.",
    },
    {
        label: summaryLabelTypes.Heidrick,
        implementation: _jsx(HeidrickScoreLevelFunctionMatrix, {}),
        legend: (_jsx(StarsLegendComponent, { scoreType: "Heidrick", label: summaryLabelTypes.Heidrick, scoreKey: ScoreTypes.Heidrick })),
        scoreKey: ScoreTypes.Heidrick,
        scoreFormatter: starsScoreFormatter,
        description: "Average Heidrick Score for leaders, assessing alignment to current role requirements " +
            "in terms of functional impact, organizational impact, capability, and potential.",
    },
    {
        label: summaryLabelTypes.AI,
        implementation: _jsx(AiScoreLevelFunctionMatrix, {}),
        legend: (_jsx(StarsLegendComponent, { scoreType: "AI", label: summaryLabelTypes.AI, scoreKey: ScoreTypes.AI })),
        scoreKey: ScoreTypes.AI,
        scoreFormatter: starsScoreFormatter,
        description: "Average AI score for leaders, predicting success in current roles from work history, " +
            "skills, and qualifications.",
    },
    {
        label: summaryLabelTypes.Functional,
        implementation: _jsx(FunctionalLevelFunctionMatrix, {}),
        legend: (_jsx(PercentageLegend, { label: summaryLabelTypes.Functional, scoreKey: ScoreTypes.Functional, useScores: useGetLeadershipScoresQuery, scoreType: "Functional", descriptionPopup: descriptionPopup, useThreshold: useGetLeadershipThresholdsQuery, isDescending: false })),
        scoreKey: ScoreTypes.Functional,
        scoreFormatter: percentageScoreFormatter,
        description: "Average Functional Impact Score for leaders, assessing alignment with " +
            "Functional Impact requirements for their current role.",
    },
    {
        label: summaryLabelTypes.Organizational,
        implementation: _jsx(OrganizationalLevelFunctionMatrix, {}),
        legend: (_jsx(PercentageLegend, { label: summaryLabelTypes.Organizational, scoreType: "Organizational", descriptionPopup: descriptionPopup, scoreKey: ScoreTypes.Organizational, useScores: useGetLeadershipScoresQuery, useThreshold: useGetLeadershipThresholdsQuery, isDescending: false })),
        scoreKey: ScoreTypes.Organizational,
        scoreFormatter: percentageScoreFormatter,
        description: "Average Organizational Impact Score for leaders, assessing alignment with " +
            "Organizational Impact requirements for their current role.",
    },
    {
        label: summaryLabelTypes.Capability,
        implementation: _jsx(CapabilityLevelFunctionMatrix, {}),
        legend: (_jsx(PercentageLegend, { label: summaryLabelTypes.Capability, scoreType: "Capabilities", descriptionPopup: descriptionPopup, scoreKey: ScoreTypes.Capability, useScores: useGetLeadershipScoresQuery, useThreshold: useGetLeadershipThresholdsQuery, isDescending: false })),
        scoreKey: ScoreTypes.Capability,
        scoreFormatter: percentageScoreFormatter,
        description: "Average Capability Score for leaders, assessing alignment with Capability requirements " +
            "for their current role.",
    },
    {
        label: summaryLabelTypes.Potential,
        implementation: _jsx(PotentialLevelFunctionMatrix, {}),
        legend: (_jsx(PercentageLegend, { label: summaryLabelTypes.Potential, scoreType: "Potential", descriptionPopup: descriptionPopup, scoreKey: ScoreTypes.Potential, useScores: useGetLeadershipScoresQuery, useThreshold: useGetLeadershipThresholdsQuery, isDescending: false })),
        scoreKey: ScoreTypes.Potential,
        scoreFormatter: percentageScoreFormatter,
        description: "Average Potential Score for leaders, assessing alignment with Potential requirements " +
            "for their current role.",
    },
];
var Dashboard = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var overview = useGetLeadershipOverviewQuery({});
    var navigate = useNavigate();
    var arrowClickables = {
        functional: function () { return navigate(getAllAssessmentRoute("FunctionalImpact")); },
        organizational: function () {
            return navigate(getAllAssessmentRoute("OrganizationalImpact"));
        },
        capability: function () { return navigate(getAllAssessmentRoute("Capability")); },
        potential: function () { return navigate(getAllAssessmentRoute("Potential")); },
    };
    var client = useClient();
    var skillGroups = useGetSkillGroupLookupsQuery({
        clientId: client.clientId,
    });
    var summaryData = (overview.isSuccess
        ? {
            overall: (_b = (_a = overview.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.overall_score.state,
            functional: (_d = (_c = overview === null || overview === void 0 ? void 0 : overview.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.functional.state,
            organizational: (_f = (_e = overview === null || overview === void 0 ? void 0 : overview.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.organizational.state,
            potential: (_h = (_g = overview === null || overview === void 0 ? void 0 : overview.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.potential.state,
            capability: (_k = (_j = overview === null || overview === void 0 ? void 0 : overview.data) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.capability.state,
        }
        : { overall: "nodata" });
    var InfoPopupWrapper = styled(Box)({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    });
    var ImageWrapper = styled(Box)({
        display: "flex",
        justifyContent: "center",
    });
    var summaryPopupDescription = (_jsxs(InfoPopupWrapper, { children: [_jsx(Typography, __assign({ component: "span" }, { children: "A summary of how your leadership aligns with role requirements across key attributes - Pivotal Expertise & Business Impact, Leadership Capabilities, and Agility & Potential - using an average group score. Further analysis is needed to determine if any misalignment is systemic or isolated to specific leaders." })), _jsx(ImageWrapper, { children: _jsx("img", { src: getRoute("SummaryImage"), width: "100%" }) })] }));
    var strengthPopupInfo = {
        title: "Leadership Summary",
        desc: summaryPopupDescription,
    };
    var secondLine = "across pivotal expertise & business impact, ".concat((_l = skillGroups.data) === null || _l === void 0 ? void 0 : _l.capability.toLowerCase(), ", and ").concat((_m = skillGroups.data) === null || _m === void 0 ? void 0 : _m.potential.toLowerCase(), ". The results ");
    var actText = "This dashboard shows how well your leaders align to role requirements " +
        secondLine +
        "indicate that, overall, leaders have noticeable, significant gaps " +
        "in meeting multiple role requirements. Use the heatmap to pinpoint, " +
        "by group and attribute, the risks requiring focused action.";
    var watchText = "This dashboard shows how well your leaders align to role requirements " +
        secondLine +
        "small to moderate gaps in meeting some role requirements. Use the heatmap " +
        "to pinpoint, by group and attribute, the areas to closely monitor and " +
        "any immediate risk areas for focused action.";
    var maintainText = "This dashboard shows how well your leaders align to role requirements " +
        secondLine +
        "that, overall, leaders are generally meeting or exceeding most role " +
        "requirements. Use the heatmap to pinpoint, by group and attribute, the " +
        "key strength areas to enhance and any emerging risk areas to monitor.";
    var summaryRightSideText = (summaryData === null || summaryData === void 0 ? void 0 : summaryData.overall) == Status.Act
        ? actText
        : (summaryData === null || summaryData === void 0 ? void 0 : summaryData.overall) == Status.Watch
            ? watchText
            : (summaryData === null || summaryData === void 0 ? void 0 : summaryData.overall) == Status.Maintain
                ? maintainText
                : "No data";
    var StatusPopup = function () {
        return (_jsx(StrengthInfoPopUp, { title: strengthPopupInfo.title, desc: strengthPopupInfo.desc }));
    };
    var overviewComponentInfo = {
        inside: (_jsx(ScoreBreakdown, { action: (_q = (_p = (_o = overview.data) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.overall_score) === null || _q === void 0 ? void 0 : _q.state, summaryRightSideText: summaryRightSideText, title: "Organization Overview", text: getOverviewTexts(summaryData), showDescriptions: true, arrowClickables: arrowClickables, statusPopup: _jsx(StatusPopup, {}) })),
    };
    return (_jsx(HeatmappedPage, { "data-testid": "DashboardPage", heatmaps: toggledHeatmaps, useScores: useGetLeadershipScoresQuery, heading: overviewComponentInfo, useOverview: useGetLeadershipOverviewQuery, countText: "leader" }));
};
var DashboardBase = function (props) {
    useEffect(function () {
        document.title = "Intelligence";
    }, []);
    var InnerContainer = styled(Box)({
        maxWidth: "1920px",
        width: "100%",
    });
    var OuterContainer = styled(Box)({
        display: "flex",
        justifyContent: "center",
        width: "100%",
    });
    var _a = usePermissions(), nineBox = _a.nineBox, diversity = _a.diversity;
    var tabs = [{ label: "Summary", hash: "" }];
    var data = [_jsx(Dashboard, {})];
    if (haveTalentReviewPages() && nineBox) {
        tabs.push({ label: "Talent Review", hash: "talent-review" });
        data.push(_jsx(TalentReview, {}));
    }
    tabs.push({ label: "Succession", hash: "succession" });
    data.push(_jsx(RoledSuccession, {}));
    tabs.push({ label: "Mobility", hash: "mobility" });
    data.push(_jsx(LeaderedSuccession, {}));
    if (haveDiversityPages() && diversity) {
        tabs.push({ label: "Demographics", hash: "demographics" });
        data.push(_jsx(Diversity, {}));
    }
    tabs.push({ label: "Risk", hash: "leadership-risk" });
    data.push(_jsx(LeadershipRisk, {}));
    var selectedTab = 0;
    if (props.selectedTabHash) {
        // Get the tab index by the hash value
        var hashToIndexMap = Object.fromEntries(tabs.map(function (tab, idx) { return [tab.hash, idx]; }));
        selectedTab = hashToIndexMap[props.selectedTabHash] || 0;
    }
    return (_jsxs(_Fragment, { children: [_jsx(HomePageHeader, {}), _jsx(OuterContainer, { children: _jsx(InnerContainer, { children: _jsx(OverviewTabs, { tabs: tabs, data: data, selectedTab: selectedTab, sx: { paddingTop: "0", width: "100%" } }) }) })] }));
};
export default DashboardBase;
