var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "react";
import { useEffect, useRef } from "react";
import { BottomCenter } from "./BottomCenter";
import { BottomLeft } from "./BottomLeft";
import { BottomRight } from "./BottomRight";
import { MiddleCenter } from "./MiddleCenter";
import { MiddleLeft } from "./MiddleLeft";
import { MiddleRight } from "./MiddleRight";
import { TopCenter } from "./TopCenter";
import { TopLeft } from "./TopLeft";
import { TopRight } from "./TopRight";
import styles from "./iconBox.module.scss";
export var mappings = [
    _jsx(BottomLeft, {}),
    _jsx(MiddleLeft, {}),
    _jsx(TopLeft, {}),
    _jsx(BottomCenter, {}),
    _jsx(MiddleCenter, {}),
    _jsx(TopCenter, {}),
    _jsx(BottomRight, {}),
    _jsx(MiddleRight, {}),
    _jsx(TopRight, {}),
];
export var IconBox = function (_a) {
    var index = _a.index, name = _a.name, nameClass = _a.nameClass;
    return (_jsxs("div", __assign({ className: styles.iconBox }, { children: [_jsx("div", { children: mappings[index] }, index), _jsx("div", __assign({ className: styles.name }, { children: _jsx("div", __assign({ className: nameClass || styles.regularFont }, { children: name })) }))] })));
};
export var InlineIconBox = function (_a) {
    var index = _a.index, name = _a.name;
    var forUnbadging = useRef(null);
    useEffect(function () {
        var _a, _b;
        var element = (_b = (_a = forUnbadging === null || forUnbadging === void 0 ? void 0 : forUnbadging.current) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.lastChild;
        if (!element) {
            return;
        }
        element.style["transform"] = "unset";
        element.style["-webkit-transform"] = "unset";
    }, [forUnbadging]);
    return (_jsxs("div", __assign({ ref: forUnbadging, className: styles.inlineIconBox }, { children: [_jsx("div", __assign({ className: styles.inlineIcon }, { children: mappings[index] })), _jsx("div", __assign({ className: styles.inlineName }, { children: name }))] })));
};
