var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Accordion, AccordionSize, AccordionShape, } from "@eightfold.ai/octuple";
import Skill from "../Skill/Skill";
import { Divider } from "@mui/material";
import styles from "./SkillGroupAccordion.module.scss";
var SkillList = function (_a) {
    var progressStepperFactory = _a.progressStepperFactory, skills = _a.skills, clientEdits = _a.clientEdits;
    return (_jsx(_Fragment, { children: skills.map(function (skill, index) { return (_jsx("div", __assign({ className: styles.skillDividerContainer }, { children: _jsxs("div", __assign({ className: "".concat(styles.skillContainer).concat(index == skills.length - 1 ? " " + styles.lastSkillContainer : "").concat(index == 0 ? " " + styles.firstSkillContainer : "") }, { children: [_jsx(Divider, { className: styles.divider }), _jsx(Skill, { name: skill.name || "", description: skill.description || "", benchmark: skill.benchmark || 0, tag: skill.tag, id: skill.id, progressStepperFactory: progressStepperFactory, clientEdits: clientEdits })] })) }), skill.name)); }) }));
};
var getAccordionHeaderStyle = function () { return ({
    fontWeight: 800,
    fontSize: "16px",
    fontFamily: "Avenir Heavy",
    color: "var(--grey-color-90)",
    backgroundColor: "var(--grey-color-10)",
    border: "1px solid var(--grey-gradient-end-color-10)",
    borderRadius: "24px",
    maxHeight: "unset",
}); };
var SkillAccordion = function (_a) {
    var progressStepperFactory = _a.progressStepperFactory, skillGroup = _a.skillGroup, clientEdits = _a.clientEdits;
    var _b = useState(true), expanded = _b[0], setExpanded = _b[1];
    return (_jsx(Accordion, __assign({ expanded: expanded, onAccordionChange: function (expanded) { return setExpanded(expanded); }, size: AccordionSize.Medium, headerProps: { style: getAccordionHeaderStyle() }, bodyProps: {
            style: __assign({ overflowY: "hidden" }, (expanded && { maxHeight: "unset" })),
        }, bordered: false, summary: skillGroup.summary, shape: AccordionShape.Rectangle }, { children: _jsx(SkillList, { progressStepperFactory: progressStepperFactory, skills: skillGroup.skills, clientEdits: clientEdits }) })));
};
var SkillGroupAccordion = function (_a) {
    var progressStepperFactory = _a.progressStepperFactory, accordionData = _a.accordionData, clientEdits = _a.clientEdits;
    return (_jsx(_Fragment, { children: accordionData === null || accordionData === void 0 ? void 0 : accordionData.map(function (skillGroup, index) {
            var nextGroup = accordionData[index + 1];
            var currentHasAccordion = !!skillGroup.summary;
            var nextHasAccordion = !!(nextGroup === null || nextGroup === void 0 ? void 0 : nextGroup.summary);
            return (_jsx("div", { children: currentHasAccordion ? (_jsx("div", __assign({ className: styles.accordionContainer }, { children: _jsx(SkillAccordion, { progressStepperFactory: progressStepperFactory, skillGroup: skillGroup, clientEdits: clientEdits }) }))) : (_jsxs("div", __assign({ className: styles.skillListContainer }, { children: [_jsx(SkillList, { progressStepperFactory: progressStepperFactory, skills: skillGroup.skills, clientEdits: clientEdits }), !nextHasAccordion && nextGroup && (_jsx(Divider, { className: styles.skillList }))] }))) }, index));
        }) }));
};
export default SkillGroupAccordion;
