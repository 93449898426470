var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "react";
export var TopLeft = function () { return (_jsxs("svg", __assign({ width: "40", height: "26", viewBox: "0 0 40 26", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [_jsx("path", { d: "M0 4C0 1.79086 1.79086 0 4 0H13V8H0V4Z", fill: "#69717F" }), _jsx("rect", { x: "0.5", y: "0.5", width: "39", height: "25", rx: "3.5", stroke: "#69717F" }), _jsx("path", { d: "M13.5 0.333374V25.3334", stroke: "#69717F" }), _jsx("path", { d: "M39.334 8.5L0.000631928 8.5", stroke: "#69717F" }), _jsx("path", { d: "M39.334 17.5L0.000631928 17.5", stroke: "#69717F" }), _jsx("path", { d: "M26.5 0V25", stroke: "#69717F" })] }))); };
