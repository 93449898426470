var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from "react";
import { useGetDataFiltersQuery } from "../../store/api/core";
import { filterListeners, getFilters, updateFilters, } from "../../providers/FilterProvider";
import Dropdown from "../Dropdown/Dropdown";
import { Box } from "@mui/material";
import { useClient } from "../../hooks/client";
import { FilterRestrictionContext, getRestrictionArrays, } from "../../providers/FilterRestrictionProvider";
export var FilterResetEventName = "FilterResetEventName";
var useDefaultRestriction = function () {
    var restrictions = useContext(FilterRestrictionContext).restrictions;
    return { entity: null, restrictions: restrictions, lookups: {} };
};
export var MultiSelectFilter = function (_a) {
    var _b;
    var _c, _d, _e, _f;
    var label = _a.label, entity = _a.entity, filterEntity = _a.filterEntity, sx = _a.sx, useOptionsQuery = _a.useOptionsQuery, useRestrictions = _a.useRestrictions;
    var resolvedFilterEntity = filterEntity ? filterEntity : entity;
    var _g = useState(), initial = _g[0], setInitial = _g[1];
    var _h = (useRestrictions || useDefaultRestriction)(), restrictionEntity = _h.entity, lookups = _h.lookups, restrictions = _h.restrictions;
    var resolvedRestrictionEntity = restrictionEntity
        ? restrictionEntity
        : entity;
    var _j = useState(), rerenderKey = _j[0], setRerenderKey = _j[1];
    var _k = useState(true), showFilter = _k[0], setShowFilter = _k[1];
    var _l = useState(true), isFirstRender = _l[0], setIsFirstRender = _l[1];
    var client = useClient();
    var clientId;
    var filters = Object.entries(getFilters() || {}).reduce(function (current, _a) {
        var _b;
        var _c, _d, _e;
        var entity = _a[0], values = _a[1];
        return (__assign(__assign({}, current), (_b = {}, _b[entity] = (((_c = restrictions === null || restrictions === void 0 ? void 0 : restrictions[resolvedFilterEntity]) === null || _c === void 0 ? void 0 : _c.size) || 0) == 0
            ? values
            : ((_e = (_d = (values || [])).filter) === null || _e === void 0 ? void 0 : _e.call(_d, function (value) { return !(restrictions === null || restrictions === void 0 ? void 0 : restrictions[resolvedRestrictionEntity].has(value)); })) || values, _b)));
    }, {});
    if (filters.clientId) {
        clientId = filters.clientId;
    }
    else {
        clientId = client.clientId;
    }
    var restrictedFilters = __assign(__assign(__assign({}, filters), getRestrictionArrays(restrictions)), (_b = {}, _b[resolvedFilterEntity] = "", _b));
    var optionsResponse = (useOptionsQuery || useGetDataFiltersQuery)({
        clientId: clientId,
        filters: restrictedFilters,
    });
    var options = ((_e = (_d = (_c = optionsResponse === null || optionsResponse === void 0 ? void 0 : optionsResponse.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[entity]) === null || _e === void 0 ? void 0 : _e.filter(function (_a) {
        var _b;
        var value = _a.value;
        return (((_b = restrictions === null || restrictions === void 0 ? void 0 : restrictions[resolvedRestrictionEntity]) === null || _b === void 0 ? void 0 : _b.size) || 0) == 0 ||
            (restrictions === null || restrictions === void 0 ? void 0 : restrictions[resolvedRestrictionEntity].has(value));
    })) || [];
    Array.from((restrictions === null || restrictions === void 0 ? void 0 : restrictions[resolvedRestrictionEntity]) || []).forEach(function (value) {
        if (!(options === null || options === void 0 ? void 0 : options.map(function (_a) {
            var value = _a.value;
            return value;
        }).includes(value))) {
            options.push({ label: (lookups === null || lookups === void 0 ? void 0 : lookups[value]) || value, value: value });
        }
    });
    var isSoloRestriction = ((_f = restrictions === null || restrictions === void 0 ? void 0 : restrictions[resolvedRestrictionEntity]) === null || _f === void 0 ? void 0 : _f.size) == 1;
    var getSelected = function () {
        var _a, _b, _c;
        if (isSoloRestriction) {
            return ((_a = options === null || options === void 0 ? void 0 : options[0]) === null || _a === void 0 ? void 0 : _a.value) ? [(_b = options === null || options === void 0 ? void 0 : options[0]) === null || _b === void 0 ? void 0 : _b.value] : null;
        }
        if (((_c = getFilters()) === null || _c === void 0 ? void 0 : _c[resolvedFilterEntity]) != null) {
            return getFilters()[resolvedFilterEntity];
        }
    };
    var updateSelected = function () {
        setInitial(getSelected() || []);
    };
    useEffect(function () {
        window.addEventListener(FilterResetEventName, updateSelected);
        return function () {
            window.removeEventListener(FilterResetEventName, updateSelected);
        };
    }, []);
    useEffect(function () {
        var updateListener = function (_, _a) {
            var rerenderKey = _a.rerenderKey;
            if (!rerenderKey) {
                return;
            }
            updateSelected();
        };
        filterListeners.add(updateListener);
        return function () {
            filterListeners.delete(updateListener);
        };
    }, []);
    var updateFiltersLocally = function (selected) {
        var _a;
        updateFilters((_a = {}, _a[resolvedFilterEntity] = selected, _a));
    };
    useEffect(function () {
        setInitial(getSelected() || []);
    }, [JSON.stringify(options), JSON.stringify(getSelected())]);
    // Check options only on first render
    useEffect(function () {
        if (isFirstRender && !optionsResponse.isLoading) {
            if ((options === null || options === void 0 ? void 0 : options.length) === 0 && entity !== "assigned_box") {
                setShowFilter(false);
            }
            setIsFirstRender(false);
        }
    }, [isFirstRender, options, optionsResponse.isLoading]);
    if (!showFilter)
        return null;
    return (_jsx(Box, __assign({ component: "span", sx: sx || {} }, { children: _jsx(Dropdown, { rerenderMe: function () {
                updateSelected();
                setRerenderKey(Math.random());
            }, "data-testid": "".concat(entity, "-filter"), options: options, label: label, disabled: isSoloRestriction, initial: initial == null ? getSelected() : initial, multiple: true, onDropdownVisibilityChange: function (visible) {
                !visible && updateSelected();
            }, onChangeMultiple: updateFiltersLocally, loading: optionsResponse.isLoading }, "filter-".concat(entity, "-").concat(rerenderKey)) }), "container-".concat(entity, "-").concat(rerenderKey)));
};
