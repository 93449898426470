var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from "react";
import { Slider, TextInput, TextInputWidth } from "@eightfold.ai/octuple";
import styles from "./EditableSlider.module.scss";
export var functionalOrganizational = {
    minimum: 1,
    maximum: 5,
    multiplier: 1,
    allowEdit: function (value) {
        return !isNaN(Number(value)) &&
            (value.length == 0 ||
                (value.length == 1 && Number(value) <= 5 && Number(value) >= 1));
    },
};
export var capabilitiesPotential = {
    minimum: 100,
    maximum: 500,
    multiplier: 100,
    allowEdit: function (value) {
        return !isNaN(Number(value)) &&
            (value.length == 0 ||
                (value.length <= 4 && Number(value) <= 5 && Number(value) >= 1));
    },
};
var SliderInput = function (_a) {
    var value = _a.value, onChange = _a.onChange, allowEdit = _a.allowEdit;
    var idKey = useState(Math.random())[0];
    var previous = useRef();
    previous.current = value.toString();
    var idName = "SliderInput-".concat(idKey);
    return (_jsx("div", __assign({ className: styles.textInputContainer }, { children: _jsx(TextInput, { id: idName, "data-testid": "SliderInput", clearable: false, value: value, className: styles.textInputContainer, style: { width: "60px", minWidth: "60px" }, inputWidth: TextInputWidth.fill, onChange: function (event) {
                var changeTo = event.target.value;
                if (!allowEdit(changeTo)) {
                    var element = document.getElementById(idName);
                    element.value = previous.current;
                    return;
                }
                previous.current = changeTo.toString();
                if (changeTo.length < 1) {
                    return;
                }
                onChange(Number(changeTo));
            } }) })));
};
export var IdEchoingEditableSlider = function (props) {
    var _a;
    return (_jsx(EditableSlider, __assign({}, props, { onChange: function (value) {
            props.onChange(props.id, value);
        }, initial: ((_a = props.clientEdits) === null || _a === void 0 ? void 0 : _a[props.id]) || props.initial })));
};
export var EditableSlider = function (_a) {
    var initial = _a.initial, calculated = _a.calculated, onChange = _a.onChange, _b = _a.valueDescription, maximum = _b.maximum, minimum = _b.minimum, multiplier = _b.multiplier, allowEdit = _b.allowEdit;
    var _c = useState(initial), value = _c[0], setValue = _c[1];
    var onVisible = minimum;
    var visibleDots = [];
    while (onVisible <= maximum) {
        visibleDots.push(onVisible);
        onVisible += multiplier;
    }
    var labels = [
        "Undeveloped",
        "Emerging",
        "Proficient",
        "Advanced",
        "Expert",
    ];
    var loudLabelIndex = Math.floor((labels.length - 1) * ((value * multiplier - minimum) / (maximum - minimum)));
    return (_jsxs("div", __assign({ "data-testid": "EditableSlider", className: styles.wholeContainer }, { children: [_jsxs("div", __assign({ className: styles.sliderRegion }, { children: [_jsx("div", __assign({ className: styles.sliderContainer }, { children: _jsx(Slider, { style: {
                                "--slider-track-background-color": "var(--blue-color-60)",
                                "--slider-track-border-color": "var(--blue-color-60)",
                                "--slider-rail-background-color": "var(--blue-color-20)",
                                "--slider-rail-border-color": "var(--blue-color-20)",
                                "--slider-thumb-color": "var(--blue-color-60)",
                            }, className: styles.sliderOverrides, min: minimum, max: maximum, hideValue: true, hideMin: true, hideMax: true, visibleDots: visibleDots, dotClassNames: styles.bigDot, dotStyle: {
                                border: 0,
                                borderRadius: 0,
                                width: "2px",
                                backgroundColor: "var(--grey-color-10)",
                            }, step: 1, value: value * multiplier, onChange: function (newValue) {
                                var scaledValue = Number(newValue) / multiplier;
                                setValue(scaledValue);
                                onChange(scaledValue);
                            } }) })), _jsx("div", __assign({ className: styles.sliderLabels }, { children: labels.map(function (label, index) { return (_jsx("div", __assign({ className: "".concat(styles.label, " ").concat(index == loudLabelIndex ? styles.selectedLabel : "") }, { children: label }))); }) }))] })), _jsx(SliderInput, { value: value, onChange: function (value) {
                    setValue(value);
                    onChange(value);
                }, allowEdit: allowEdit }), _jsx("div", __assign({ className: styles.calculated }, { children: calculated }))] })));
};
